import { Fragment, useState, useEffect } from 'react'
import { getDocId, fetchAll } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import JobsInput from './JobsComp/JobsInput';
  

  export default function Jobs() {

  const navigate=useNavigate()
  const [jobList, setJobList]= useState([])
  const [jobsInput, setJobsInput]=useState(false)

  const navigateJob= async (id)=>{
    const jobId= await getDocId("jobs", "WorkOrder", id)
    navigate(`/jobs/${jobId}`)
  }

  useEffect(()=>{
    fetchAll("jobs", setJobList)
    console.log(jobList)
  },[])

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        {jobsInput !== true?
        <>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Jobs</h1>
            <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 mt-3 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={()=>setJobsInput(true)}
                >
                Add Job
            </button>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the jobs.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Work Order
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                    Feeder/Fuse
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {jobList.map((job, index) => (
                  <tr key={index} onClick={()=> navigateJob(job.WorkOrder)} className="hover:cursor-pointer hover:bg-gray-100 bg-white-200 hover: cursor-pointer">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {job.WorkOrder}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {job.Location}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {job.Feeder}/{job.Fuse}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.status}</td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <h1 className="text-orange-600 hover:text-gray-900">View</h1>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
          :
          <JobsInput open={setJobsInput}/>
      }
        </div>
        
      )}