import React, {useEffect, useState } from 'react';
import sidebarLogo from '../assets/sidebarLogo.svg';
import { useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../src/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";


function SignIn() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);

    return (
      <>
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-900">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-36 w-auto"
              src={sidebarLogo}
              alt="VPS"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-neutral-50">Sign in to your account</h2>
           
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md m-4">
            <div className="bg-neutral-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
             
             {/* Form Below */}
              <div className="space-y-6 m-4 bg-neutral-800">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-50">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-neutral-50">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-neutral-50">
                      Remember me
                    </label>
                  </div>
  
                  <div className="text-sm">
                    <a href="#" className="font-medium text-orange-600 hover:text-gray-900">
                      Forgot your password?
                    </a>
                  </div>
                </div>
  
                <div>
                  <button
                    onClick={() => logInWithEmailAndPassword(loginEmail, loginPassword)}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Sign in
                  </button>
                </div>
                 <div className="text-sm py-4">
                    <a href="/signup" className="text-center font-medium text-orange-600 hover:text-gray-900">
                      Don't have an account?
                    </a>
                  </div>
              </div>
                 {/* Form Above */}

                

            </div>
          </div>
        </div>
      </>
    );
 }

      export default SignIn;