import { Fragment, useEffect, useState } from "react";
import { XIcon } from '@heroicons/react/outline'
import { addJob, fetchAllTagged } from "../../Firebase";


export default function JobsInput({open}){

const [customers, setCustomers]= useState([])
const [errorMessage, setErrorMessage]= useState(null)
const [form, setForm]= useState({})

const onChangeHandler=(event)=>{
    setForm({
        ...form,
        [event.target.name]:event.target.value
    })
}

const submitJob=()=>{
    const necessaryFields=['workOrder', 'location', 'feeder', 'fuse', "customerId"]
    for(var i=0; i<necessaryFields.length; i++){
        if(form[necessaryFields[i]]===undefined){
            setErrorMessage("You left out a necessary field. Make sure you've entered all the required information")
            return
        }
    }
            setErrorMessage(null)
            addJob(form)
            setTimeout(()=>{
                open(false)
            },500)
}

useEffect(()=>{
    fetchAllTagged("customers", "Name", setCustomers)
}, [])

return(
<>
<div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
<div>
<div className='w-inherit flex justify-end'>
<XIcon className="text-gray-600 h-6 w-6 hover:cursor-pointer" onClick={()=>open(false)}/>
</div>

        <h3 className="text-lg leading-6 font-medium text-gray-900">New Job</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">You can manually input a job here.</p>
        </div>
        <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            *Work Order:
            </label>
            <input
            name="workOrder" onChange={(event)=>onChangeHandler(event)}
            className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md"
            />

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" >*Location:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" onChange={(event)=>onChangeHandler(event)} name="location"/>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">*Feeder:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" name="feeder" onChange={(event)=>onChangeHandler(event)}></input>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">*Fuse:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" name="fuse" onChange={(event)=>onChangeHandler(event)}></input>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">*Customer</label>
            <select className="max-w-lg" name="customerId" onInput={(event)=>onChangeHandler(event)}>
                <option></option>
                {customers.map((customer, i)=>{
                    return(
                        <Fragment key={i}>
                            <option value={customer.id}>{customer.item.Name}</option>
                        </Fragment>
                    )
                })}
            </select>

            <button className="bg-orange-600 text-white rounded p-2 mt-4 block" onClick={()=>submitJob(form)}>Submit Job</button>
            <br/>
            {errorMessage && 
            <div className="w-full">
            <p className="text-red-500 p-2">{errorMessage}</p>
            </div>
            } 
            <br/><p className="p-2 text-sm">* indicates required field</p>
            </div>
        </div>
    </div>
</>
);
}