import { useEffect, useState } from 'react'
import { fetchMultiple, fetchProfile, fetchMultipleTagged, updateField, fetchOneTagged, uploadPic, submitTask, auth, getTasks, getGroupTasks, lastThirty} from '../Firebase'
import { useParams } from 'react-router-dom'
import Modal from '../components/Modal'
import {Timestamp } from 'firebase/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import SideDisplay from './TeamMemberComp.js/SideDisplay'
import ReceiptDisplay from './TeamMemberComp.js/ReceiptDisplay'
import { XIcon } from '@heroicons/react/outline'
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/solid'
import fallbackAvatar from '../assets/VPSFallbackAvatar.svg'

const tabs = [
  { name: 'Profile'},
  { name: 'Tasks'},
  { name: 'Receipts'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TeamMember() {
  const {member}= useParams()
  const [user]= useAuthState(auth)
  const [info, setInfo]= useState([])
  const [open, setOpen]= useState(false)
  const [viewer, setViewer]=useState(false)
  const [groups, setGroups]= useState([`${member}`])
  const [taskbox, setTaskbox]=useState(false)
  const [receiptbox, setReceiptBox]=useState(false)
  const [taskInput, setTaskInput]= useState(false)
  const [taskForm, setTaskForm]= useState({})
  const [receipts, setReceipts]= useState([])
  const [tasks, setTasks]= useState([])
  const [tasksCompleted, setTasksCompleted]= useState([])
  const [selectedTab, setSelectedTab]=useState("Profile")
  const [selectedReceipt, setSelectedReceipt]=useState({})
  const [selectedTask, setSelectedTask]= useState({})
  const [attachment, setAttachment]= useState(null)
  const [attachmentUrl, setAttachmentUrl]= useState("")
  const [attachmentFileName, setAttachmentFileName]= useState("")
  const [errorMessage, setErrorMessage]= useState(null)

  const openModal=()=>{
    setViewer(false)
    setOpen(true)
  }

  const openViewer=()=>{
    if(open==true){
      setOpen(false)
    }
  }

  const selectReceipt=(event)=>{
    const id= event.target.id
    fetchOneTagged("receipts", id, setSelectedReceipt)
    setTimeout(()=>{
      setReceiptBox(true)
    }, 200)
  }

  const openReceiptbox=()=>{
    if(receipts.length==0){
      return
    }
    const firstReceipt= receipts[0].id
    setReceiptBox(true)
    fetchOneTagged("receipts", firstReceipt, setSelectedReceipt)
    console.log(selectedReceipt, receipts)
  }

  const reimburse=(event, boolean)=>{
    try{
      const id=event.target.id
      if(boolean==true){
        const status= 
        {isReimbursed:true}
        updateField("receipts", id, status)
        console.log("Reimbursed")
      }
      else{
        const status=
        {isReimbursed:false}
        updateField("receipts", id, status)
        console.log("Imbursement Revoked")
      }
      setTimeout(()=>{
        fetchMultipleTagged("receipts", "reimburseUser", member, setReceipts)
        fetchOneTagged("receipts", id, setSelectedReceipt)
      },500)
      console.log("Task Complete")
    }
    catch(err){
      alert(err)
    }
  }

  const selectTask=(event)=>{
    const id= event.target.id
    fetchOneTagged("tasks", id, setSelectedTask)
    setTimeout(()=>{
      setTaskbox(true)
    }, 200)
  }

  const openTaskbox=()=>{
    // if(tasks.length==0){
    //   return
    // }
    const firstTask=tasks[0].id
    fetchOneTagged("tasks", firstTask, setSelectedTask)
    setTimeout(()=>{
      setTaskbox(true)
    }, 500)
  }

  const completeTask=(event, boolean)=>{
    try{
      const id=event.target.id
      if(boolean==true){
        const status= 
        {Completed:true,
          CompleteDate: Timestamp.now()
        }
        updateField("tasks", id, status)
        console.log("Task Complete")
      }
      else{
        const status=
        {Completed:false,
        CompleteDate: ""
        }
        updateField("tasks", id, status)
        console.log("Task Pending")
      }
      setTimeout(()=>{
        getTasks(member, false, setTasks)
        getTasks(member, true, setTasksCompleted)
        fetchOneTagged("tasks", id, setSelectedTask)
      },500)
      console.log("Task Complete")
    }
    catch(err){
      alert(err)
    }
  }

  const dateChange=(event)=>{
    let dateString= event.target.value
    let date= new Date(dateString)
    let dateStamp= Timestamp.fromDate(date)
    let time= {
      seconds:dateStamp.seconds+18000
    }
    const final= new Timestamp(time.seconds, 0)
    setTaskForm({
        ...taskForm,
        [event.target.name]:final
    })
}

const newPic= (event)=>{
  setAttachmentFileName(event.target.files[0].name)
  setErrorMessage(null)
  if(event.target.files[0].size>10000000){
  setErrorMessage('This file is too big. Please choose one under 10MB')
  return
  }
  else if(event.target.files[0].type!== "application/pdf" && 
          event.target.files[0].type!== "image/jpeg" &&
          event.target.files[0].type!== "image/png"){
    setErrorMessage('Please choose a supported file type. Ex: PDF, PNG, JPEG')
    return
  }
  else{
    setErrorMessage(null)
    uploadPic("task-attachments", attachment, attachment.name, setAttachmentUrl)
  }
}

  useEffect(()=>{
    fetchProfile("uid", member, setInfo)
    fetchMultipleTagged("receipts", "reimburseUser", member, setReceipts)
    getTasks(member, false, setTasks)
    getTasks(member, true, setTasksCompleted)
    // getGroupTasks(groups, false, setTasks)
    // getGroupTasks(groups, true, setTasksCompleted)
    // lastThirty("tasks", "CompleteDate", setTasksCompleted)
    setTimeout(()=>{
      openTaskbox()
      openReceiptbox()
    }, 1000)
  },[member,open, taskInput, selectedTab])

  return (
    <>
      <div className="h-full grid grid-cols-5">
        <div className="col-span-3 flex flex-col min-w-0 flex-1 overflow-hidden">
        
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            

              <article>
                {/* Profile header */}
                <div>
                  <div className="max-w-5xl mx-auto">
                    <div className="sm:flex sm:items-end">
                      <div className="flex">
                        <img
                          className="h-24 w-24 rounded-full ring-4 ring-white"
                          src={info.profilePicUrl ? info.profilePicUrl : fallbackAvatar}
                          alt=''
                        />
                      </div>
                      <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                          <h1 className="text-2xl font-bold text-gray-900 truncate">{`${info.fName} ${info.lName}`}</h1>
                        </div>
                        <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                        </div>
                      </div>
                    </div>
                    <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                      <h1 className="text-2xl font-bold text-gray-900 truncate">{`${info.fName} ${info.lName}`}</h1>
                    </div>
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            onClick={()=>setSelectedTab(tab.name)}
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-pink-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover: cursor-pointer text-gray-700 hover:border-gray-300',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>


  {/* Conditional Rendering for Tabs and Modals */}
  {selectedTab && selectedTab=="Profile"?

  // Profile
  <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1 text-sm text-gray-900">{`${info.fName} ${info.lName}`}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Email</dt>
          <dd className="mt-1 text-sm text-gray-900">{info.email}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
          <dd className="mt-1 text-sm text-gray-900">{info.phoneNumber}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Birthday</dt>
          <p></p>
          <dd id="birthday" className="mt-1 text-sm text-gray-900">{info.birthday}</dd>
        </div>
    </dl>
  </div>

  // Tasks
  : 
  selectedTab=="Tasks" && tasksCompleted.length | tasks.length !==0?
  <div className='max-w-xl'>
  <div className='mt-6 ml-6'>
    <button onClick={()=>setTaskInput(true)}>+ Add Task</button>
  </div>
  {taskInput &&
      <div className='max-w-xl'>
        <div className=' flex justify-end'>
          <button onClick={()=>setTaskInput(false)}>x</button>
        </div>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="grid grid-cols-6 gap-6">


            <div className="mt-1 sm:mt-0 sm:col-span-6">
                <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm justify-center text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onInput={(event)=>setAttachment(event.target.files[0])} onChange={(event)=>newPic(event)}/>
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
                  {errorMessage && 
                  <div>
                    <p className='text-small text-red-400 text-center'>{errorMessage}</p>
                  </div>}
          {/* <div className='flex justify-center'>
          {attachmentUrl !=="" && <button id={selectedTask[0].id} className='bg-indigo-600 text-white p-2 rounded my-2' onClick={(event)=>attachPhoto(event)}>Upload Photo</button>}
          </div> */}
              </div>

            <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                type="text"
                name="Description"
                id="email-address"
                autoComplete="email"
                className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-72 h-32 shadow-sm sm:text-sm border border-gray-300 rounded-md"
                onChange={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}
              />
            </div>

        <div className="col-span-6 sm:col-span-4">
        <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
        <input className="border border-gray-300 rounded p-2" type="text" name="Subject" onChange={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}></input>
        </div>

            <div className="col-span-6 sm:col-span-3 mb-5">
              <label htmlFor="DueDate" className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
            <input className="border border-gray-300 rounded p-2" type='date' name='DueDate' onChange={(event)=>dateChange(event)}></input>
            </div>

          <div className='col-span-3 sm:col-span-3 mt-5'>
          <button className='bg-orange-600 text-white p-2 h-12 rounded' onClick={()=>submitTask([member], user.uid, taskForm, attachmentUrl, attachmentFileName, setTaskInput)}>Assign Task</button>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
        }
    <h1 className='mt-3 ml-8'>Incomplete</h1>
  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-5">
      {tasks.map((task, i)=>{
        return(
          <div key={i}>
          <div
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
          >
          <div className="flex-shrink-0">
          </div>
          <div className="flex-1 min-w-0">
            <a className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" id={task.id} onClick={(event)=>selectTask(event)}/>
              <p>Due:</p>
              <p className="text-sm font-medium text-gray-900">{task.item.DueDate.toDate().toDateString()}</p>
              {task.item.Subject?
                <>
              <p>Subject: {task.item.Subject}</p>
                </>
                :
                <>
                <p>Subject: N/A</p>
                </>
              }
              <p className='text-red-400'>Pending</p>
            </a>
          </div>
        </div>
        </div>
        )
        })}
  </div>
    <h1 className='mt-3 ml-8'>Complete</h1>
  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-5">
      {tasksCompleted.map((task, i)=>{
        return(
          <div key={i}>
          <div
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
          >
          <div className="flex-shrink-0">
          </div>
          <div className="flex-1 min-w-0">
            <a className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" id={task.id} onClick={(event)=>selectTask(event)}/>
              <p>Due:</p>
              <p className="text-sm font-medium text-gray-900">{task.item.DueDate.toDate().toDateString()}</p>
              {task.item.Subject?
                <>
              <p>Subject: {task.item.Subject}</p>
                </>
                :
                <>
                <p>Subject: N/A</p>
                </>
              }
              <p className='text-green-400'>Complete</p>
            </a>
          </div>
        </div>
        </div>
        )
        })}
  </div>
  </div>
  : 
  selectedTab=="Tasks" && tasks.length ==0?
  <div className='max-w-xl'>
  <div className='mt-6 ml-6'>
  {/* <button onClick={()=>setTaskInput(true)}>+ Add Task</button> */}
  {taskInput &&
      <div className='max-w-xl'>
        <div className=' flex justify-end'>
          <button onClick={()=>setTaskInput(false)}>x</button>
        </div>
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="grid grid-cols-6 gap-6">

          <div className="mt-1 sm:mt-0 sm:col-span-6">
                <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm justify-center text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onInput={(event)=>setAttachment(event.target.files[0])} onChange={(event)=>newPic(event)}/>
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PDF, JPG, GIF up to 10MB</p>
                    {errorMessage && 
                  <div>
                    <p className='text-small text-red-400 text-center'>{errorMessage}</p>
                  </div>}
                  </div>
                </div>
          {/* <div className='flex justify-center'>
          {attachmentUrl !=="" && <button id={selectedTask[0].id} className='bg-indigo-600 text-white p-2 rounded my-2' onClick={(event)=>attachPhoto(event)}>Upload Photo</button>}
          </div> */}
              </div>

            <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                type="text"
                name="Description"
                id="email-address"
                autoComplete="email"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-96 h-32 shadow-sm sm:text-sm border border-gray-300 rounded-md"
                onChange={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}
              />
            </div>

            <div className="col-span-6 sm:col-span-3 mb-5">
              <label htmlFor="DueDate" className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
            <input className="border border-gray-300 rounded p-2" type='date' name='DueDate' onChange={(event)=>dateChange(event)}></input>
            </div>

            

          <div className='col-span-3 sm:col-span-3 mt-5'>
          <button className='bg-orange-600 text-white p-2 h-12 rounded' onClick={()=>submitTask(member, user.uid, taskForm, attachmentUrl, attachmentFileName, setTaskInput)}>Assign Task</button>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
        }
  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-5">
      {tasks.map((task, i)=>{
        return(
          <div key={i}>
          <div
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
          >
          <div className="flex-shrink-0">
          </div>
          <div className="flex-1 min-w-0">
            <a className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" id={task.id} onClick={(event)=>selectTask(event)}/>
              <p>Due:</p>
              <p className="text-sm font-medium text-gray-900">{task.item.DueDate.toDate().toDateString()}</p>
              {task.item.Completed==true?
              <p className='text-green-400'>Complete</p>
              :
              <p className='text-red-400'>Pending</p>
              }
            </a>
          </div>
        </div>
        </div>
        )
        })
        
        }
  </div>
  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-5">
      {tasksCompleted.map((task, i)=>{
        return(
          <div key={i}>
          <div
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
          >
          <div className="flex-shrink-0">
          </div>
          <div className="flex-1 min-w-0">
            <a className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" id={task.id} onClick={(event)=>selectTask(event)}/>
              <p>Due:</p>
              <p className="text-sm font-medium text-gray-900">{task.item.DueDate.toDate().toDateString()}</p>
              <p className='text-green-400'>Complete</p>
            </a>
          </div>
        </div>
        </div>
        )
        })
        
        }
  </div>
  <div className='mx-3 my-4 p-2'>
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No tasks</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by assigning a new task.</p>
      <div className="mt-6">
        <button
          type="button"
          onClick={()=>setTaskInput(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Task
        </button>
      </div>
    </div>


  </div>
  </div> 
  </div>
  : 


  // Receipts list
  selectedTab=="Receipts" && receipts.length !==0?
  <div className='max-w-xl'>
  <div className='mt-4 ml-4 max-w-md flex justify-start'>
  <button className=' p-2 rounded' onClick={()=>openModal()}>+ Add Receipts</button>
  </div>
{/* Receipt View Window */}
  {viewer && 
  <div className='ml-3 max-w-xl items-center'>
        return(
        <div className="flex-col">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
      <div className='w-inherit flex justify-end'>
     <XIcon className='h-6 w-6' onClick={()=>setViewer(false)}/>
      </div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Receipt Detail</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{selectedReceipt.item.date.toDate().toDateString()}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Vendor</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedReceipt.item.vendor}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Payment Method</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedReceipt.item.paymentMethod}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Expense Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedReceipt.item.category}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Equipment</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedReceipt.item.equipment}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Amount</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">$ {selectedReceipt.item.amount}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedReceipt.item.description}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <a href={selectedReceipt.item.receiptUrl} className="text-sm font-medium text-orange-600 hover:text-orange-700">View Image</a>
          </div>
        </dl>
      </div>
    </div>
  </div>
    </div>
    }

  {open ? <Modal closed={setOpen}/>:

  // Form for New Receipts
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-5">
      {receipts.map((receipt, i)=>{
        return(
          <div key={i}>
          <div
          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
          >
          <div className="flex-shrink-0">
          </div>
          <div className="flex-1 min-w-0">
            <a className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" id={receipt.id} onClick={(event)=>selectReceipt(event)}/>
              <p className="text-sm font-medium text-gray-900">{receipt.item.vendor}</p>
              <p className="text-sm font-medium text-gray-900">{receipt.item.date.toDate().toDateString()}</p>
              <p className="text-sm text-orange-600 truncate flex">Details
              <ArrowRightIcon className="h-4 w-4 ml-4 " />
              </p>
            </a>
          </div>
        </div>
        </div>
        )
        })}
    </div>}
  </div>

  : 
  selectedTab=="Receipts" && receipts.length==0 ?
  <div className='mx-3 my-4 p-2'>
  {open && <Modal closed={setOpen}/>}
  <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No receipts</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by uploading a new receipt.</p>
      <div className="mt-6">
        <button
          type="button"
          onClick={()=>openModal()}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Receipt
        </button>
      </div>
    </div>
  </div>
  :
  <div></div>}
              </article>
            </main>
        
          </div>
        </div>
        {selectedTab=="Receipts" && selectedReceipt[0] && <ReceiptDisplay info={selectedReceipt[0]} reimburse={reimburse}></ReceiptDisplay>}
        {selectedTab=="Tasks" && selectedTask[0] && <SideDisplay info={selectedTask[0]} setOpen={setTaskbox} complete={completeTask} className="col-span-2">
    </SideDisplay>}
      </div>
    </>
  )
}