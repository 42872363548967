import { Fragment, useEffect, useState } from 'react'
import Modal from '../components/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { fetchAll, fetchAllTagged, fetchOne, fetchOneReturn, orderedQuery } from '../Firebase'




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FleetDetails(props) {
 const tabs = [
        { name: 'Details', href:'#', current: false },
        { name: 'DVIR', href:'#', current: false}
]
  const {vehicleId}= useParams()
  const [open, setOpen]= useState(false)
  const [source, setSource]= useState("")
  const [selectedTab, setSelectedTab]=useState("Details")
  const [vehicle, setVehicle]= useState([])
  const [DVIR, setDVIR]= useState([])
  const unit= "Unit#"
  
  const openModal=(event)=>{
    setSource(event.target.id)
    setTimeout(()=>{setOpen(true)
    }, 200)
  }

    useEffect(()=>{
      fetchOne("equipment", vehicleId, setVehicle)
      orderedQuery('dvir', 'equipmentId', vehicleId, 'dateAdded', 'asc', setDVIR)
    },[])
    

  return (
    <>
      <div className="h-full flex">
        {/* <button className='bg-green-500 p-2 rounded text-white' onClick={()=>console.log(drivers)}>Get the Deets</button> */}
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
      {open==true ? <Modal closed={setOpen} source={source}/> : 
              <article>
                {/* Profile header */}
                <div>
                  <div className='m-4'>
                    <p className='text-2xl'>Fleet Management</p>
                  </div>

                  <div className='absolute top-0 right-0 flex-shrink-0 mr-2'>
                    <div className='flow rounded-lg border border-gray-300 bg-white px-6 py-5 w-40 my-6 shadow-sm flex flex-row items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600'>
                      <a href="https://app.momentumiot.com/" target='_blank' className="focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              <p className="text-sm font-medium text-gray-900">Momentum</p>
                              <ArrowSmRightIcon className='h-7 w-7'/>
                      </a>
                    </div>
                  </div>
                </div>
                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <p
                            onClick={()=>setSelectedTab(tab.name)}
                            id={tab.name}
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-pink-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </p>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                {
                
            //     selectedTab=="Momentum" ?
            //     <div className=" gap-4 p-4">
            //         <div  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 w-40 my-6 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600">
            //             <a href="https://app.momentumiot.com/" className="focus:outline-none">
            //               <span className="absolute inset-0" aria-hidden="true" />
            //               <p className="text-sm font-medium text-gray-900">Open in Browser</p>
            //             </a>
            //         </div>
            //   <iframe src="https://app.momentumiot.com/" height="800" width="100%" title="description"></iframe>
            //   </div>
                selectedTab=="Details" ?
                <div className=" gap-4 p-4">
                  <div className='w-inherit h-inherit'>
                    <div className='flex justify-start align-top'>
                      <h1 className='font-bold text-lg underline'>Vehicle Details</h1>
                    </div>
                    <div className='flex flex-col justify-center align-middle'>
                        {vehicle ?
                    <div className='ml-3 mt-3 p-4 rounded-lg border border-gray-300 bg-gray-50'>
                        <p className='mt-2 font-bold'>Name:</p>
                        <p className='ml-2'>{vehicle.Name}</p>
                        <p className='mt-2 font-bold'>Unit Number:</p>
                        <p className='ml-2'>{vehicle[unit]}</p>
                        <p className='mt-2 font-bold'>License Plate:</p>
                        <p className='ml-2'>{vehicle.LicensePlate}</p>
                        <p className='mt-2 font-bold'>Vin:</p>
                        <p className='ml-2'>{vehicle.Vin}</p>
                        </div>
                        :
                        <span></span>
                    }
                    </div>
                  </div>
                </div>
                :
                selectedTab=="DVIR" ?
                <div className=" gap-4 p-4">
                  {DVIR.length>0 ?
                  <div className='flex flex-wrap max-w-xl sm:w-'>
                  {DVIR.map((report, i)=>{ 
                    return(
                    <div key={i} className="relative rounded-lg border border-gray-300 bg-white py-3 px-4 my-4 mx-2 shadow-sm flex flex-col space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600">
                    <p>{report.item.dateAdded.toDate().toDateString().slice(4)}</p>
                    <a href={report.item.fileRef} target='_blank' className='text-orange-400'>View ></a>
                    </div>
                      )
                  })}
                  </div>
                  :
                  <div  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 w-40 my-6 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600">
                      <p>No Reports For This Vehicle</p>
                  </div>
                  }
                      {/* <button className='bg-green-500 p-2 rounded-lg text-whiite' onClick={()=>console.log(DVIR)}>Get The Deets</button> */}
                  
                </div>
                :
                <span></span>
                }
              </article>
}
            </main>
        
          </div>
        </div>
      </div>
    </>
  )
}

