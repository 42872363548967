import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


const data = [
    {name: 'Page A', uv: 400, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 300, pv: 500, amt: 500},
    {name: 'Page C', uv: 200, pv: 1200, amt: 1200},
    {name: 'Page D', uv: 600, pv: 800, amt: 800},
];

export default function LineChartComponent() {



    return (
    <div className="mt-5 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div className="text-base font-normal text-gray-900 px-4 py-4 sm:px-6">
          Line Chart
        </div>
        <div className="px-4 py-5 sm:p-6">
            <div style={{ width: '100%', height: 250 }}>
                <ResponsiveContainer>
                <LineChart height={200} data={data}>
                <Line type="monotone" dataKey="uv" stroke="#ea580c" />
                <CartesianGrid stroke='#ccc' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip/>
                </LineChart> 
                </ResponsiveContainer>
            </div>
            
        </div>
    </div>
    )
  }