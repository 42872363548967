import React from 'react';

function NotFound(props) {
    return (
        <div>
            <h1>404 Error. Page Not Found.</h1>
        </div>
    );
}

export default NotFound;