import { Timestamp } from "firebase/firestore"
import { useEffect, useState } from "react"
import { fetchAll, fetchAllTagged, fetchMultiple, fetchMultipleTagged, fetchOneTagged, lastThirty, orderedQuery, orderedQueryM, updateField } from "../Firebase"
import ReceiptDisplay from './TeamMemberComp.js/ReceiptDisplay'

export default function Receipts(){

    const [received, setReceived]= useState(true)
    const [receiptbox, setReceiptBox]= useState(false)
    const [receipts, setReceipts]=useState([])
    const [loggedReceipts, setLoggedReceipts]= useState([])
    const [paymentTypes, setPaymentTypes]=useState(["Credit", "Debit", "Other"])
    const [methods, setMethods]= useState([])
    const [selectedType, setSelectedType]=useState("All Receipts")
    const [users, setUsers]= useState([])
    const [selectedReceipt, setSelectedReceipt]= useState({})

    const selectReceipt=(tag)=>{
        const id= tag
        if(tag!==undefined){
          fetchOneTagged("receipts", id, setSelectedReceipt)
          setTimeout(()=>{
          setReceiptBox(true)
          }, 200)
        }
        else{
          return
        }
    }

    const openReceiptbox=()=>{
        if(receipts.length===0){
            setReceived(false)
        return
        }
        else{
            const firstReceipt= receipts[0].id
            fetchOneTagged("receipts", firstReceipt, setSelectedReceipt)
            setReceiptBox(true)
        }
    }

    const reimburse=(event, boolean)=>{
        try{
            const id=event.target.id
            if(boolean===true){
                const status= 
                {isReimbursed:true}
                updateField("receipts", id, status)
                console.log("Reimbursed")
        }
        else{
            const status=
            {isReimbursed:false}
            updateField("receipts", id, status)
            console.log("Imbursement Revoked")
        }
        setTimeout(()=>{
            fetchAllTagged("receipts", 'date', setReceipts)
            fetchOneTagged("receipts", id, setSelectedReceipt)
          },500)
          console.log("Reimbursment Complete")
        }
        catch(err){
          alert(err)
        }
      }

      const groupByType=()=>{
        if(selectedType==="All Receipts"){
          orderedQuery("receipts", "sageEntered", false, "date", "asc", setReceipts)
        }
        else{
          filterReceipts()
        }
      }

    const sageEntry=(event, boolean)=>{
      try{
          const id=event.target.id
          if(boolean===true){
              const status= 
              {sageEntered:true,
              logDate: Timestamp.now()}
              updateField("receipts", id, status)
              console.log("Sage Entered")
      }
      else{
          const status=
          {sageEntered:false,
          logDate: ""}
          updateField("receipts", id, status)
          console.log("Entry Revoked")
      }
      setTimeout(()=>{
          lastThirty(setLoggedReceipts, "receipts", "sageEntered", true, "logDate", setUsers)
          groupByType()
      },500)
      console.log("Task Complete")
      }
      catch(err){
      alert(err)
      }
  }

  const filterReceipts= async()=>{
    const list=[]
    const methods= await fetchMultipleTagged("lu_paymentMethod", "Type", selectedType, setMethods)
    const toCompare= await orderedQuery("receipts", "sageEntered", false, "date", "asc", setReceipts)
    setReceipts([])
      for(var i=0; i<toCompare.length; i++){
        for(var j=0; j<methods.length; j++){
          if(toCompare[i].item.paymentMethod==methods[j].id){
            list.push(toCompare[i])
          }
        }
      }
      setReceipts(list)
  }


    useEffect(()=>{
        groupByType()
        lastThirty(setLoggedReceipts, "receipts", "sageEntered", true, "logDate", setUsers)
        setTimeout(()=>{
            openReceiptbox()
        }, 1000)
    }, [received, selectedType])


    return (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Receipts</h1>
              {/* <button className="bg-green-500 p-2 rounded" onClick={()=>console.log(methods)}>Get the Deets</button> */}
              <div>
              </div>
              {/* <p className="mt-2 text-sm text-gray-700">
                A list of all the receipts.
              </p> */}
              {/* <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 mt-3 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                Add Receipt
              </button> */}
            </div>
          </div>
          <div className="mt-8">To be Logged
              <select className="border rounded mt-3 ml-3" onInput={(event)=>setSelectedType(event.target.value)}>
                <option>All Receipts</option>
                {paymentTypes.map((type, i)=>{
                  return(
                    <option key={i}>{type}</option>
                  )
                })}
              </select>
          </div>
          <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg w-1/2">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Date of Purchase
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                    Amount
                  </th>
                  <th scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Entered in Sage
                  </th>
                </tr>
              </thead>
                  {receipts &&
              <tbody className="divide-y divide-gray-200 bg-white">
                {receipts.map((receipt, index) => (
                    <tr key={index} className="bg-white-200 hover:cursor-pointer hover:bg-gray-100 focus-within:bg-orange-400">
                    <td onClick={()=> selectReceipt(receipt.id)} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {receipt.item.date &&
                      <p>{receipt.item.date.toDate().toDateString().slice(4)}</p>
                    }
                    </td>
                    <td onClick={()=> selectReceipt(receipt.id)} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      ${receipt.item.amount}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <input scope="col" id={receipt.id} type="checkbox" onClick={(event)=>sageEntry(event, true)} checked={receipt.item.sageEntered}/>
                    </td>
                  </tr>
                ))}
              </tbody>
}
            </table>
            </div>

            <div className="mt-8">Logged in the Last 30 Days</div>
            <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg w-1/2">
              <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Logged On
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                    Amount
                  </th>
                  <th scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Entered in Sage
                  </th>
                </tr>
              </thead>
                  {loggedReceipts &&
              <tbody className="divide-y divide-gray-200 bg-white">
                {loggedReceipts.map((receipt, index) => (
                    <tr key={index} className="bg-white-200 hover:cursor-pointer hover:bg-gray-100 focus-within:bg-orange-400">
                    <td onClick={()=> selectReceipt(receipt.id)} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {receipt.item.logDate &&
                      <p>{receipt.item.logDate.toDate().toDateString().slice(4)}</p>
                    }
                    </td>
                    <td onClick={()=> selectReceipt(receipt.id)} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      ${receipt.item.amount}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <input scope="col" id={receipt.id} type="checkbox" onClick={(event)=>sageEntry(event, false)} checked={receipt.item.sageEntered}/>
                    </td>
                  </tr>
                ))}
              </tbody>
}
            </table>
          </div>
          {selectedReceipt[0] && <ReceiptDisplay info={selectedReceipt[0]} reimburse={reimburse} sage={sageEntry}></ReceiptDisplay>}
        </div>
      )
    }