import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fetchAll, uploadPic, addReceipt, fetchAllTagged } from "../Firebase";
import { useParams } from "react-router-dom";
import { XIcon } from '@heroicons/react/outline'


export default function Modal({closed, source}){

const [paymentLU, setPaymentLU]= useState([])
const [paymentMethod, setPaymentMethod]= useState([])
const [paymentType, setPaymentType]=useState(["Credit", "Debit", "Other"])
const [errorMessage, setErrorMessage]= useState(null)
const [vendors, setVendors]= useState([])
const [form, setForm]= useState({
    isReimbursed: false,
    logDate: "",
    sageEntered:false
})
const [receiptFile, setReceiptFile]= useState(null)
const [url, setUrl]= useState("")
const {member}= useParams()

const onChangeHandler=(event)=>{
    setForm({
        ...form,
        [event.target.name]:event.target.value
    })
}

const enterToSage=(event)=>{
    if(event.target.checked===true){
        setForm({
            ...form,
            [event.target.name]:true,
            ["logDate"]: Timestamp.now()
        })
    }
    else{
        setForm({
            ...form,
            [event.target.name]:false,
            ["logDate"]: ""
        })
    }
}

const dateChange=(event)=>{
    let dateString= event.target.value
    let date= new Date(dateString)
    let dateStamp= Timestamp.fromDate(date)
    let time= {
        seconds:dateStamp.seconds+18000
    }
    const final= new Timestamp(time.seconds, 0)
    setForm({
        ...form,
        [event.target.name]:final
    })
}

const newPic= (event)=>{
    uploadPic("receipt-images", receiptFile, receiptFile.name, setUrl)
    setTimeout(()=>{
        setForm({...form,
        [event.target.name]:url})
    }, 500)
}

const submitReceipt=()=>{
    const necessaryFields=['amount', 'expenseType', 'equipment', 'date', 'description', 'paymentMethod', 'paymentType', 'vendor']
    for(var i=0; i<necessaryFields.length; i++){
        if(form[necessaryFields[i]]===undefined){
            setErrorMessage("You left out a necessary field. Make sure you've entered all the required information")
            return
        }
    }
            setErrorMessage(null)
            addReceipt("receipts", form, member)
            setTimeout(()=>{
                closed(false)
            },500)
}

useEffect(()=>{
    fetchAllTagged("lu_paymentMethod", "Name", setPaymentLU)
    fetchAll("lu_expense_type", setPaymentMethod)
    fetchAll("vendors", setVendors)
    setForm({...form, receiptUrl:url})
}, [url])

return(
<>
<div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
<div>
<div className='w-inherit flex justify-end'>
<XIcon className="text-gray-600 h-6 w-6" onClick={()=>closed(false)}/>
</div>

        <h3 className="text-lg leading-6 font-medium text-gray-900">New Receipt</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">You can manually input a receipt here.</p>
        </div>
        <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            *Amount:
            </label>
            <input
            name="amount" type="number" min="0" onChange={(event)=>onChangeHandler(event)}
            className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md"
            />
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="paymentMethod">*Payment Method:</label>
            <select className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" name="paymentMethod" onChange={(event)=>onChangeHandler(event)}>
            <option></option>
            {paymentLU.map((type,i)=>{
                return(
                <option key={i} value={type.id}>{type.item.Name}</option>
                )
            })}
            </select>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="paymentType">*Payment Type:</label>
            <select className="rounded text-sm" name="paymentType" onChange={(event)=>onChangeHandler(event)}>
            <option></option>
            {paymentType.map((type,i)=>{
                return(
                    <option key={i}>{type}</option>
                    )
                })}
            </select>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="expenseType">*Expense Type:</label>
            <select className="rounded text-sm" name="expenseType" onChange={(event)=>onChangeHandler(event)}>
            <option></option>
            {paymentMethod.map((type,i)=>{
                return(
                    <option key={i}>{type.Name}</option>
                    )
                })}
            </select>

            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="vendor">*Vendor:</label>
            <select className="rounded text-sm" name="vendor" onChange={(event)=>onChangeHandler(event)}>
            <option></option>
            {vendors.map((type,i)=>{
                return(
                    <option key={i}>{type.Name}</option>
                    )
                })}
            </select>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="vendor">*Equipment:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" onChange={(event)=>onChangeHandler(event)} name="equipment"/>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="date">*Purchase Date:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" name="date" type="date" onChange={(event)=>dateChange(event)}></input>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="fileRef">Image:</label>
            <input className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" type="file" name="receiptUrl" onInput={(event)=>setReceiptFile(event.target.files[0])} onChange={(event)=>newPic(event)}></input>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">*Description:</label>
            <textarea className="max-w-lg text-sm block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500  border-gray-300 rounded-md" name="description" onChange={(event)=>onChangeHandler(event)}></textarea>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="isReimbursed" >Reimbursed?</label>
            <input className="ml-2 mt-2  mr-3 border-2 rounded border-gray-200" name="isReimbursed" type="checkbox" onChange={(event)=> setForm({...form, [event.target.name]:event.target.checked})}></input>
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2" for="sageEntered">Entered to Sage?</label>
            <input className="" name="sageEntered" type="checkbox" onClick={(event)=>enterToSage(event)}/>
            <button className="bg-orange-600 text-white rounded p-2 mt-4 block" onClick={()=>submitReceipt()}>Submit Receipt</button>
            <br/>
            {errorMessage && 
            <div className="w-full">
            <p className="text-red-500 p-2">{errorMessage}</p>
            </div>
            } 
            <br/><p className="p-2 text-sm">* indicates required field</p>
            </div>
        </div>
    </div>
</>
);
}