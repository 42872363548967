import { Timestamp } from "firebase/firestore"
import { useEffect, useState } from "react"
import { fetchOneTagged, updateField, lastThirty, tasksAndUsers} from "../Firebase"
import TaskInput from "./TasksComp/TaskInput"
import SideDisplay from "./TeamMemberComp.js/SideDisplay"

export default function Tasks(info){

const [received, setReceived]= useState(true)
const [taskbox, setTaskbox]= useState(false)
const [taskInput, setTaskInput]= useState(false)
const [tasksIncomplete, setTasksIncomplete]=useState([])
const [usersIncomplete, setUsersIncomplete]=useState([])
const [tasksComplete, setTasksComplete]=useState([])
const [usersComplete, setUsersComplete]=useState([])
const [selectedTask, setSelectedTask]=useState({})

const dateParse=(date)=>{
    var newDate = date.toDate()
    console.log(newDate)
}

// Opens SideDisplay
const openDisplay=()=>{
    if(tasksIncomplete.length===0){
        setTimeout(()=>{
            setReceived(false)
        }, 1000)
    return
    }
    else{
        const firstTask= tasksIncomplete[0].id
        fetchOneTagged("tasks", firstTask, setSelectedTask)
        setTaskbox(true)
    }
}

// Select task in Display
const selectTask=(tag)=>{
    const id= tag
    fetchOneTagged("tasks", id, setSelectedTask)
    setTimeout(()=>{
      setTaskbox(true)
    }, 200)
  }



const completeTask=(event, boolean)=>{
    try{
        const id=event.target.id
        if(boolean===true){
            const status= 
            {Completed:true,
                CompleteDate: Timestamp.now()
            }
            updateField("tasks", id, status)
            setTimeout(()=>{
                tasksAndUsers(false, setTasksIncomplete, setUsersIncomplete)
                lastThirty(setTasksComplete, "tasks", "Completed", true, "CompleteDate", setUsersComplete)
            }, 500)
            console.log("Task Complete")
        }
        else{
            const status=
            {Completed:false,
                CompleteDate: ""
            }
            updateField("tasks", id, status)
            setTimeout(()=>{
                tasksAndUsers(false, setTasksIncomplete, setUsersIncomplete)
                lastThirty(setTasksComplete, "tasks", "Completed", true, "CompleteDate", setUsersComplete)
            }, 500)
            console.log("Task Pending")
        }
        setTimeout(()=>{
        fetchOneTagged("tasks", id, setSelectedTask)
    },500)
    console.log("Task Complete")
}
catch(err){
    alert(err)
}
}

useEffect(()=>{
    tasksAndUsers(false, setTasksIncomplete, setUsersIncomplete)
    lastThirty(setTasksComplete, "tasks", "Completed", true, "CompleteDate", setUsersComplete)
    openDisplay()
}, [received, taskInput])

    return(
<>
{taskInput ? <TaskInput open={setTaskInput}/>
    :  
    <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Comms</h1>
            <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 mt-3 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={()=>setTaskInput(true)}
                >
                Add Comm
            </button>
            {/* <button className="bg-green-500 p-2 rounded" onClick={()=>console.log(usersComplete)}>Get the Deets</button> */}
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            </div>
        </div>
        <div className="mt-8">Incomplete Tasks</div>
        <div className="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg w-1/2">
            <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Assigned To
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Due Date
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Subject
                </th>
                </tr>
            </thead>
                {tasksIncomplete &&
            <tbody className="divide-y divide-gray-200 bg-white">
                {tasksIncomplete.map((task, index) => (
                    <tr key={index} onClick={()=> selectTask(task.id)} className="bg-white-200 hover:cursor-pointer hover:bg-gray-100 focus-within:bg-orange-400">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {usersIncomplete[index]}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {task.item.DueDate &&
                        <>
                    <p>{task.item.DueDate.toDate().toDateString().slice(4)}</p>
                    {/* <button className="bg-green-500 p-2 rounded-lg text-white" onClick={()=>dateParse(task.item.DueDate)}>New Date</button> */}
                        </>
                    }
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {task.item.Subject?
                        <>
                        <p>{task.item.Subject}</p>
                        </>
                        :
                        <>
                        <p>N/A</p>
                        </>
                        }
                    </td>
                </tr>
                ))}
            </tbody>
}
            </table>
            </div>

<div className="mt-8">Completed in the Last 30 Days</div>
            <div className="-mx-4 mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg w-1/2">
            <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Assigned To
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Completion Date
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Subject
                </th>
                </tr>
            </thead>
                {tasksComplete &&
            <tbody className="divide-y divide-gray-200 bg-white">
                {tasksComplete.map((task, index) => (
                    <tr key={index} onClick={()=> selectTask(task.id)} className="bg-white-200 hover:cursor-pointer hover:bg-gray-100 focus-within:bg-orange-400">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {`${usersComplete[index]}`}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {task.item.CompleteDate &&
                    <p>{task.item.CompleteDate.toDate().toDateString().slice(4)}</p>
                        }
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {task.item.Subject?
                        <>
                        <p>{task.item.Subject}</p>
                        </>
                        :
                        <>
                        <p>N/A</p>
                        </>
                        }
                    </td>
                </tr>
                ))}
            </tbody>
}
            </table>
            </div>
        <div>
        </div>
        {selectedTask[0] && <SideDisplay info={selectedTask[0]} complete={completeTask}></SideDisplay>}
        </div>
        }
        </>
        )}