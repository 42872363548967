import { useState, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth";
import { updateDoc, doc, Timestamp} from "firebase/firestore";
import { db, auth, getDocId, uploadPic } from "../Firebase";
import { useNavigate } from "react-router-dom";
import { fetchProfile } from "../Firebase";
import { getDownloadURL } from "firebase/storage";


export default function EditProfile(){

    const [profPic, setProfPic]= useState(null)
    const [loaded, setLoaded]= useState(true)
    const [url, setUrl]= useState("")
    const navigate= useNavigate();
    const [profile, setProfile]= useState({});
    const [user]= useAuthState(auth);
    const [form, setForm]= useState({
        fName: "",
        lName:"",
        email: "",
        phoneNumber: " ",
        birthDate: "",
        profilePicUrl:""
    })
    
    const userProfile=()=>{
            fetchProfile("uid", user.uid, setProfile)
            setTimeout(()=>{
                setLoaded(false)
                setForm(profile)
            }, 500)
    }
    
    useEffect(() => {
        userProfile()
        }, [loaded]);
        
        
        const onChangeHandler=(event)=>{
            setForm({
                ...form, 
                [event.target.name]:event.target.value
            })
        }
        
        const dateChange=(string)=>{
            const date= new Date(string)
            const timestamp= Timestamp.fromDate(date)
        return timestamp
    }

        const newPic= (event)=>{
            setProfPic(event.target.files[0]);
            uploadPic(profPic, setUrl);
        }

        const submitUpdate= async()=>{
            try{
                const id= await getDocId("users", "uid", user.uid)
                const userRef= doc(db, "users", id)
                    await updateDoc(userRef,
                            {fName:form.fName,
                            lName:form.lName,
                            email:form.email,
                            phoneNumber:form.phoneNumber,
                            birthday:dateChange(form.birthDate),
                            profilePicUrl: url
                        })
            console.log("Update Successful!")
            navigate("/myprofile")
        }
        catch (err){
            console.log(`submit error: ${err}`)
        }
    }

    return(
<>

{/* Update Form */}
<div className="h-full text-start">
    <h1 className="text-2xl font-bold text-gray-900 truncate mt-4 ml-4">Edit Info for {profile.fName}</h1><br></br>
<div  name="user" className= "max-w-xl rounded m-2 pb-4 bg-gray-200 border-2 border-gray-300">
<div className="mt-6 w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    {/* <button onClick={()=>console.log(profile, form)} className="bg-green-400 text-white rounded">Get the Deets</button> */}
    <div className='grid grid-cols-2 gap-2 justify-items-start w-full'>
    <div className="col-span-1">
        <label htmlFor="fName" className="block mt-2 mr-4">First Name:</label>
        <input onChange={(event)=>onChangeHandler(event)} className="rounded block w-full p-2 mb-5" type='text' name="fName" value={form.fName}></input>
    </div>
    <div className="col-span-1">
        <label htmlFor="lName" className="block mt-2 mr-4">Last Name:</label>
        <input onChange={(event)=>onChangeHandler(event)} className="rounded block w-full p-2 mb-5" type='text' name="lName" value={form.lName}></input>
    </div>
    </div>
    <div className='grid grid-cols-2 gap-2 justify-items-start w-full'>
    <div className="col-span-1">
        <label htmlFor="birtDate" className="block mt-2 mr-4">Birthday:</label>
        <input onChange={(event)=>onChangeHandler(event)} className="rounded block w-full p-2 mb-5" type='date' name="birthDate" value={form.birthDate}></input>
    </div>
    <div className="col-span-1">
        <label htmlFor="number" className="block mt-2 mr-4">Phone Number:</label>
        <input onChange={(event)=>onChangeHandler(event)} className="rounded block w-full p-2 mb-5" type='text' name="phoneNumber" value={form.phoneNumber}></input>
    </div>
    </div>
    <div className='justify-items-start min-w-xl'>
        <label htmlFor="email" className="block mt-2 mr-4">Email:</label>
        <input onChange={(event)=>onChangeHandler(event)} className="rounded p-2 flex mb-5 w-3/4" type='text' name="email" value={form.email}></input>
    </div>
    <label htmlFor="pic" className="block mt-2 mr-4">Profile Pic:</label>
    <div className="flex items-center h-50 w-90 text-center">
    <div name="pic" className="flex h-20 w-80 pt-5 pl-5 border-dashed border-2 items-center justify-center bg-gray-300 hover:bg-gray-400">
        <label for="profilePicUrl">
        <input onChange={(event)=>newPic(event)} className="p-2 mb-5 h-100% w-100% w-full h-full" type='file' name="profilePicUrl"></input>

        </label>
    </div>
    </div>
</div>
</div>
<button onClick={submitUpdate} type="submit" className="bg-slate-800 rounded-md text-gray-100 p-2 hover:bg-slate-700 mt-4 ml-4">Submit Changes</button>
</div>
</>

    )
}