import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import Dashboard from './screens/Dashboard';
import NotFound from './screens/404Error';
import Team from './screens/Team';
import Jobs from './screens/Jobs';
import Sidebar from './components/Sidebar';
import MyProfile from './screens/MyProfile';
import Settings from './screens/Settings';
import EditProfile from './screens/EditProfile';
import TeamMember from './screens/TeamMember';
import JobDetails from './screens/JobDetails';
import Receipts from './screens/Receipts';
import Tasks from './screens/Tasks';
import Policies from './screens/Policies';
import Fleet from './screens/Fleet';
import FleetDetails from './screens/FleetDetails';


function App(props) {
  
  function ExternalMomentum() {
    window.location.href = 'https://app.momentumiot.com/';
    return null;
  }

  function ExternalSage() {
    window.location.href = 'https://signin.swizznet.com/swizznet/index.html';
    return null;
  }
  
  function ExternalMonday() {
    window.location.href = 'https://voltage-power-solutions-llc.monday.com';
    return null;
  }

  return (
    <div>
        <Routes>
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/signup" element={<SignUp />} />

          
          <Route path="/" element={<Sidebar />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path="/team" element={<Team/>} />
          <Route path="/team-member/:member" element={<TeamMember/>} />
          <Route path="/jobs" element={<Jobs/>} />
          <Route path="/jobs/:jobsId" element={<JobDetails/>}/>
          <Route path='/receipts' element={<Receipts/>}/>
          <Route path='/taskcenter' element={<Tasks/>} />
          <Route path='/policies' element={<Policies /> }/>
          <Route path='/fleet' element={<Fleet /> }/>
          <Route path='/fleet/:vehicleId' element={<FleetDetails/>}/>
          <Route path='/sage' element={<ExternalSage /> }/>
          <Route path='/monday' element={<ExternalMonday /> }/>
          <Route path="/myprofile" element={<MyProfile/>} />
          <Route path="/editprofile" element={<EditProfile/>}/>
          <Route path="/settings" element={<Settings/>} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
 
 );
}

export default App;