import { XIcon } from "@heroicons/react/outline"
import { useState } from "react"
import ImageUpload from "../../components/ImageUpload"
import { Timestamp } from "firebase/firestore"
import { addToPolicyBank, uploadPic } from "../../Firebase"


export default function PolicyBankInput({open}){

    const [form, setForm]= useState({})
    const [attachment, setAttachment]= useState(null)
    const [attachmentFileName, setAttachmentFileName]= useState("")
    const [attachmentUrl, setAttachmentUrl]= useState("")
    const [errorMessage, setErrorMessage]= useState(null)


    
    const onChangeHandler=(e)=>{
        setForm({...form, [e.target.name]:e.target.value})
    }

    const dateChange=(event)=>{
        let dateString= event.target.value
        let date= new Date(dateString)
        let dateStamp= Timestamp.fromDate(date)
        let time= {
            seconds:dateStamp.seconds+28800
        }
        const final= new Timestamp(time.seconds, 0)
        setForm({
            ...form,
            [event.target.name]:final
        })
    }

    const newPic= (event)=>{
        console.log(form)
        setErrorMessage(null)
        if(event.target.files[0].size>10000000){
        setErrorMessage('This file is too big. Please choose one under 10MB')
        return
        }
        else if(event.target.files[0].type!== "application/pdf" && 
                event.target.files[0].type!== "image/jpeg" &&
                event.target.files[0].type!== "image/png"){
        setErrorMessage('Please choose a supported file type. Ex: PDF, PNG, JPEG')
        return
        }
        else{
        setErrorMessage(null)
        uploadPic("policy-attachments", attachment, attachment.name, setAttachmentUrl)
        .then((url)=>{
            setForm({...form, ["attachment"]:url, ["attachmentName"]:event.target.files[0].name})
        })
    }
}

    const onSubmitHandler=()=>{
        addToPolicyBank(form)
        open(false)
    }

    return(
        <>
        <div className="flex flex-col min-w-fit justify-center mt-3">
            <div className="flex justify-end align-top w-1/2 hover: cursor-pointer" onClick={()=>open(false)}><XIcon className="bg-gray-300 rounded-full border border-gray-700 h-3 w-3"/></div>
            <div className="mt-3">
            <label className="font-medium block text-gray-900">Policy Title</label>
            <input className="" type="" name="title" onChange={(e)=>onChangeHandler(e)}></input>
            </div>
            <div className="mt-3">
            <label className="font-medium block text-gray-900">Policy Number</label>
            <input className="" type="" name="policyNumber" onChange={(e)=>onChangeHandler(e)}></input>
            </div>
            <div className="mt-3">
            <label className="font-medium block text-gray-900">Effective Date</label>
            <input className="" type="date" name="effectiveDate" onChange={(e)=>dateChange(e)}></input>
            </div>
            <div className="mt-3 min-h-fit">
            <ImageUpload form={form} setForm={setForm} bucket={"policy-attachments"}/>
            </div>
            <div className="mt-3">
                <button className="bg-orange-500 text-white rounded-lg p-2" onClick={()=>onSubmitHandler()}>Add to Policy Bank</button>
            </div>
        </div>
        </>
    )
}