import { Fragment, useEffect, useState } from 'react'
import Modal from '../components/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { fetchAll, fetchAllTagged, fetchOne, fetchOneReturn, orderedQuery } from '../Firebase'




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Fleet(props) {
  const navigate=useNavigate()
  const {jobsId}= useParams()
  const [open, setOpen]= useState(false)
  const [source, setSource]= useState("")
  const [selectedTab, setSelectedTab]=useState("Details")
  const [fleet, setFleet]=useState([])
  const [drivers, setDrivers]= useState([])
  const unit= "Unit#"
  
  const openModal=(event)=>{
    setSource(event.target.id)
    setTimeout(()=>{setOpen(true)
    }, 200)
  }

    useEffect(()=>{
      fetchAllTagged("equipment", "Unit#", setFleet)
    },[])
    

  return (
    <>
      <div className="h-full flex">
        {/* <button className='bg-green-500 p-2 rounded text-white' onClick={()=>console.log(drivers)}>Get the Deets</button> */}
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
      {open==true ? <Modal closed={setOpen} source={source}/> : 
              <article>
                {/* Profile header */}
                <div>
                  <div className='m-4'>
                    <p className='text-2xl'>Fleet Management</p>
                  </div>

                  <div className='absolute top-0 right-0 flex-shrink-0 mr-2'>
                    <div className='flow rounded-lg border border-gray-300 bg-white px-6 py-5 w-40 my-6 shadow-sm flex flex-row items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600'>
                      <a href="https://app.momentumiot.com/" target='_blank' className="focus:outline-none">
                              <span className="absolute inset-0" aria-hidden="true" />
                              <p className="text-sm font-medium text-gray-900">Momentum</p>
                              <ArrowSmRightIcon className='h-7 w-7'/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className=" gap-4 p-4">
                  <div className='w-inherit h-inherit'>
                    <div className='flex justify-start align-top'>
                      <h1>Fleet Details</h1>
                    </div>
                      <div className='-mx-4 mt-2 shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg w-1/2'>
                        <table className='min-w-full divide-y divide-gray-300 rounded-lg border bg-gray-50 mt-5 '>
                          <thead className='min-w-full divide-y rounded-lg divide-gray-300'>
                            <tr className='border'>
                            <th th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Unit#</th>
                            <th th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                            </tr>
                          </thead>
                          <tbody className='"divide-y divide-gray-200 bg-white rounded-lg'>
                      {fleet.map((vehicle, i)=>{
                        return(
                          <tr key={i} onClick={()=>navigate(`/fleet/${vehicle.id}`)} className='border bg-white hover:bg-gray-200'>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{vehicle.item[unit]}</td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium w-1/4 text-gray-900 sm:pl-6">{vehicle.item.Name}</td>
                          </tr>
                                  )
                        })}
                            </tbody>
                            </table>
                            </div>
                  </div>
                </div>
              </article>
}
            </main>
        
          </div>
        </div>
      </div>
    </>
  )
}

