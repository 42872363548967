import { Fragment } from 'react'
import { ChatAltIcon, TagIcon, UserCircleIcon } from '@heroicons/react/solid'
import fallbackAvatar from '../assets/VPSFallbackAvatar.svg'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CommentList({comments}) {

  return (
    <div className="flow-root mt-3 max-h-64 overflow-y-scroll">
      <ul role="list" className="mb-8">
        {comments.map((comment, i) => (
          <li key={i}>
            <div className="relative pb-8">
              {i !== comment.length - 1 ? (
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {comment.Type === 'comment' ? (
                  <>
                    <div className="relative">
                      <img
                        className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                        src={comment.User.PicRef ? comment.User.PicRef : fallbackAvatar}

                        alt=""
                      />

                      <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                        <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <a href={comment.User.href} className="font-medium text-gray-900">
                            {comment.User.Name}
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">{comment.Created_at.toDate().toDateString().slice(4)}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{comment.Text}</p>
                      </div>
                    </div>
                  </>
                ) : comment.Type === 'assignment' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                      <div className="text-sm text-gray-500">
                        <a href={comment.User.href} className="font-medium text-gray-900">
                          {comment.User.Name}
                        </a>{' '}
                        assigned{' '}
                        <a href={comment.assigned.href} className="font-medium text-gray-900">
                          {comment.assigned.name}
                        </a>{' '}
                        <span className="whitespace-nowrap">{comment.Created_at.toDate().toDateString().slice(4)}</span>
                      </div>
                    </div>
                  </>
                ) : comment.Type === 'tags' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-0">
                      <div className="text-sm leading-8 text-gray-500">
                        <span className="mr-0.5">
                          <a href={comment.User.href} className="font-medium text-gray-900">
                            {comment.User.Name}
                          </a>{' '}
                          added tags
                        </span>{' '}
                        <span className="mr-0.5">
                          {comment.tags.map((tag) => (
                            <Fragment key={tag.name}>
                              <a
                                href={tag.href}
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex-shrink-0 flex items-center justify-center">
                                  <span
                                    className={classNames(tag.color, 'h-1.5 w-1.5 rounded-full')}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{tag.name}</span>
                              </a>{' '}
                            </Fragment>
                          ))}
                        </span>
                        <span className="whitespace-nowrap">{comment.Created_at.toDate().toDateString().slice(4)}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
