import { Timestamp } from "firebase/firestore"
import { Fragment, useEffect, useState } from "react"
import { submitTask, uploadPic, auth, fetchAllTagged, orderedQuery, usersFromGroups } from "../../Firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { XIcon, ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/outline"

export default function TaskInput({open}){

    const commTypes=["Bulletin", "Policy", "Discussion", "Task", "Request", "Other"]
    const [user]= useAuthState(auth)
    const [assignee, setAssignee]=useState([])
    const [assigneeList, setAssigneeList]=useState([])
    const [taskForm, setTaskForm]= useState({})
    const [listView, setListView]= useState(false)
    const [users, setUsers]= useState([])
    const [group, setGroup]= useState([])
    const [admin, setAdmin]= useState([])
    const [linemen, setLinemen]= useState([])
    const [drillers, setDrillers]=useState([])
    const [attachment, setAttachment]= useState(null)
    const [attachmentFileName, setAttachmentFileName]= useState("")
    const [attachmentUrl, setAttachmentUrl]= useState("")
    const [errorMessage, setErrorMessage]= useState(null)


    const clearAll=()=>{
        setAssignee([])
        setAssigneeList([])
    }

    const assignIndividual=(name, id, op, index)=>{
        var individual=document.getElementById(name)
        var assignees=[...assignee]
        var assigneesList=[...assigneeList]
        
        if(op=="add" && assignees.includes(id)){
            if(individual.checked==true){
                return
            }
            else if(individual.checked==false){
                assignees.pop(id)
                assigneesList.pop(name)
            }
        }
        else if(op=="add"){
            assignees.push(id)
            assigneesList.push(name)
        }
        else if(op=="remove"){
            assignees.splice(index, 1)
            assigneesList.splice(index, 1)
            if(individual){
                individual.checked=false
            }
        }
        setAssigneeList(assigneesList)
        setAssignee(assignees)
    }

    const checkAll=(event)=>{
        var drillTeam= document.getElementById("All Drill Team")
        var admins= document.getElementById("All Admin")
        var lineWorkers= document.getElementById("All Line Workers")
        var team= document.getElementById("All Team Members")

        if(event.target.checked===true){
            if(event.target.value==="All Drill Team"){
                setGroup([...group, event.target.value])
                usersFromGroups(group, setAssignee, setAssigneeList)
            }
            else if(event.target.value==="All Admin"){
                setGroup([...group, event.target.value])
                usersFromGroups(group, setAssignee, setAssigneeList)
            }
            else if(event.target.value==="All Line Workers"){
                setGroup([...group, event.target.value])
                usersFromGroups(group, setAssignee, setAssigneeList)
            }
            else if(event.target.value==="All Team Members"){   
                drillTeam.checked=true
                admins.checked=true
                lineWorkers.checked=true           
                setGroup([event.target.value])
                usersFromGroups(group, setAssignee, setAssigneeList)
            }
        }
        else{
            drillTeam.checked=false
            admins.checked=false
            lineWorkers.checked=false
            team.checked=false
            const tags= [...group]
            tags.pop(event.target.value)
            setGroup([...tags])
            setAssignee([])
            setAssigneeList([])
        }
    }

    const dateChange=(event)=>{
        let dateString= event.target.value
        let date= new Date(dateString)
        let dateStamp= Timestamp.fromDate(date)
        let time= {
            seconds:dateStamp.seconds+28800
        }
        const final= new Timestamp(time.seconds, 0)
        setTaskForm({
            ...taskForm,
            [event.target.name]:final
        })
    }

    const newPic= (event)=>{
        setAttachmentFileName(event.target.files[0].name)
        setErrorMessage(null)
        if(event.target.files[0].size>10000000){
        setErrorMessage('This file is too big. Please choose one under 10MB')
        return
        }
        else if(event.target.files[0].type!== "application/pdf" && 
                event.target.files[0].type!== "image/jpeg" &&
                event.target.files[0].type!== "image/png"){
        setErrorMessage('Please choose a supported file type. Ex: PDF, PNG, JPEG')
        return
        }
        else{
        setErrorMessage(null)
        uploadPic("task-attachments", attachment, attachment.name, setAttachmentUrl)
        }
    }

    const submitToGroup=()=>{
        submitTask(assignee, user.uid, taskForm, attachmentUrl, attachmentFileName, open)
        alert(`The following people have been assigned tasks:\n${assigneeList}`)
    }

    useEffect(()=>{
        fetchAllTagged("users", "lName", setUsers)
        orderedQuery("lu_user_type", "category", "Drill Team", setDrillers, "title", "asc")
        orderedQuery("lu_user_type", "category", "Admin", setAdmin, "title", "asc")
        orderedQuery("lu_user_type", "category", "Line Workers", setLinemen, "title", "asc")
        usersFromGroups(group, setAssignee, setAssigneeList)
    },[group])

    return(
        <div className='max-w-xl'>

            <div className=' flex justify-end'>
            <button onClick={()=>open(false)}>x</button>
            </div>

            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">

                <div className="">
                        <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="">
                        <div className="mt-1 sm:mt-0 sm:col-span-6">

                        {/* Comm Image Upload */}
                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                        <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                        >
                        <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                        </svg>
                        <div className="flex text-sm justify-center text-gray-600">
                        <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onInput={(event)=>setAttachment(event.target.files[0])} onChange={(event)=>newPic(event)}/>
                        </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                        </div>
                        {attachment !== null &&
                        <p className="mt-3 flex justify-center text-indigo-300">Image Uploaded: {attachmentFileName}</p>
                        }
                        {errorMessage && 
                            <div>
                            <p className='text-small text-red-400 text-center'>{errorMessage}</p>
                            </div>}
                            {/* <div className='flex justify-center'>
                            {attachmentUrl !=="" && <button id={selectedTask[0].id} className='bg-indigo-600 text-white p-2 rounded my-2' onClick={(event)=>attachPhoto(event)}>Upload Photo</button>}
                        </div> */}
                        </div>

                        {/*Comm Type Input */}

                        <div className="col-span-6 sm:col-span-4">
                            <label htmlFor="commtype" className="block text-sm font-medium text-gray-700">Type</label>
                            <select name="Type" className="w-full border rounded border-gray-300" onInput={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}>
                                <option></option>
                                {commTypes.map((type, i)=>{
                                    return(
                                        <Fragment key={i}>
                                            <option className="block text-sm font-medium text-gray-700">{type}</option>
                                        </Fragment>
                                    )
                                })}
                            </select>            
                        </div>

                        {/*Comm Description Input */}

                        <div className="col-span-6 sm:col-span-4">
                            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                            Description
                            </label>
                            <textarea
                            type="text"
                            name="Description"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-72 h-32 shadow-sm sm:text-sm border border-gray-300 rounded-md"
                            onChange={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}
                            />
                        </div>

                        {/*Comm Subject Input */}
                        <div className="col-span-6 sm:col-span-4">
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
                            <input className="border border-gray-300 rounded p-2" type="text" name="Subject" onChange={(event)=>setTaskForm({...taskForm, [event.target.name]:event.target.value})}></input>
                        </div>


                        {/*Comm Due Date Input */}
                        <div className="col-span-6 sm:col-span-3 mb-5">
                            <label htmlFor="DueDate" className="block text-sm font-medium text-gray-700">
                            Due Date
                            </label>
                            <input className="border border-gray-300 rounded p-2" type='date' name='DueDate' onChange={(event)=>dateChange(event)}></input>
                            {/* <button className="bg-green-500 p-2 rounded-lg text-white" onClick={()=>console.log(taskForm.DueDate.toDate())}>Check Date</button> */}
                        </div>

                        {/* Group Assignment Tool */}

                        <div className="col-span-6 sm:col-span-4">
                        <div className="">
                            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                                Assign Groups:
                            </label>
                            <div className="flex flex-row">
                                <div>
                                    <input value="All Team Members" id="All Team Members" type="checkbox" onClick={(e)=>checkAll(e)}/>
                                    <label className="p-2">All Team Members</label>
                                </div>
                                <div className="">
                                    <input value="All Line Workers" id='All Line Workers' type="checkbox" onClick={(e)=>checkAll(e)}/>
                                    <label className="p-2">All Line Workers</label>
                                </div>
                                <div className="">
                                    <input value="All Admin" id='All Admin' type="checkbox" onClick={(e)=>checkAll(e)}/>
                                    <label className="p-2">All Admin</label>
                                </div>
                                <div className="">
                                    <input value="All Drill Team" id='All Drill Team' type="checkbox" onClick={(e)=>checkAll(e)}/>
                                    <label className="p-2">All Drill Team</label>
                                </div>
                            </div>
                        </div>

        {/* Individual Assignment*/}

                        {/* <div className="mt-3">
                            <select className="border rounded" onInput={(event)=>assignIndividual(event)}>
                            <option></option>
                            {users.map((user, i)=>{
                                return(
                                    <Fragment key={i}>
                                        {assignee.includes(user.item.uid)?
                                            <Fragment key={i}>
                                            <option className="bg-red-400" value={user.item.uid}>{`${user.item.fName} ${user.item.lName}`}</option>
                                            </Fragment>
                                            :
                                            <Fragment key={i}>
                                            <option value={user.item.uid}>{`${user.item.fName} ${user.item.lName}`}</option>
                                            </Fragment>
                                        }
                                        </Fragment>
                                        )
                                    })}
                                    </select>
                                </div> */}

                            {/*Attached to Group*/}
                            <label htmlFor="individual" className="my-3 block text-sm font-medium text-gray-700"> Assign Individual:</label>
                            <div className="flex flex-row w-100% border">
                                <div className="flex justify-start align-middle">
                                    {listView ?
                                    <div className="flex justify-end align-middle h-full w-7 border" onClick={()=>setListView(false)}>
                                        <ArrowUpIcon/>
                                    </div>   
                                    :
                                    <div className="flex justify-end align-middle h-full w-7 border" onClick={()=>setListView(true)}>
                                        <ArrowDownIcon/>
                                    </div>
                                    }
                                </div>
                                <div className="flex flex-row overflow-x-scroll">
                                {assigneeList.length!==0 &&
                                    assigneeList.map((member, i)=>{
                                        return(
                                            <div className="border bg-gray-50 rounded-lg mx-2 min-w-fit flex flex-row" key={i}>
                                                <div className="w-fit flex flex-grow-0 justify-center align-middle pl-2">
                                                <p>{member}</p>
                                                </div>
                                                <div className="ml-2 flex">
                                                <XIcon className="hover:cursor-pointer h-4 w-4 rounded-full bg-gray-100 border border-gray-200" onClick={()=>assignIndividual(member, assignee[i], "remove", i)}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        
                        {listView ?
                        <div className="h-40 overflow-scroll w-100% flex flex-col border p-2">
                            {users.map((user, i)=>{
                                return(
                                    <div className="flex" key={i}>
                                    <input id={`${user.item.fName} ${user.item.lName}`} onChange={(e)=>assignIndividual(e.target.id, user.item.uid, 'add', i)} type='checkbox'/>
                                    <label className="ml-2">{`${user.item.fName} ${user.item.lName}`}</label>
                                    </div>
                                    )
                                })}
                        </div>
                        :
                        <div></div>
                        }

                </div>
                            <div className='col-span-3 sm:col-span-3 mt-5'>
                            {/* <button className="p-2 bg-green-500 text-white rounded-lg" onClick={()=>console.log(assignee, assigneeList)}>Get the Deets</button> */}
                            <button className='bg-orange-600 text-white p-2 h-12 rounded' onClick={()=>submitToGroup()}>Assign Task</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
    