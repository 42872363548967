import React from "react";
import LineChartComponent from "../components/LineChart";
import StatsBar from "../components/StatsBar";
import RadialBarChartComponent from '../components/RadialBarChart';
import MixBarChartComponent from '../components/MixBarChart';
import PercentAreaChartComponent from '../components/PercentAreaChart';



function Dashboard() {
  
  const people = [
    {
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      role: 'Admin',
      email: 'janecooper@example.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
  ]
  
  
  return (
   
   <>
   <StatsBar/>
   <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
   <LineChartComponent/>
   <RadialBarChartComponent/>
   <MixBarChartComponent/>
   <PercentAreaChartComponent/>
   </div>
   
   
   
   </>
  
   
   
  );
}
export default Dashboard;