import { useEffect, useLayoutEffect, useState } from 'react'
import Modal from '../components/Modal'
import { fetchMultiple, fetchOne, orderedQuery, fetchOneReturn } from '../Firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/solid';
import { DeviceMobileIcon } from '@heroicons/react/outline'; 

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function JobDetails() {

  const navigate=useNavigate()
  const tabs = [
        { name: 'Details', href: '#', current: false },
        { name: 'Site Videos', href: '#', current: false },
        { name: 'Tailboards', href: '#', current: false },
    ]
  const {jobsId}= useParams()
  const [open, setOpen]= useState(false)
  const [source, setSource]= useState("")
  const [selectedTab, setSelectedTab]=useState("Details")
  const [selectedVideo, setSelectedVideo]= useState({})
  const [details, setDetails]= useState([])
  const [customer, setCustomer]= useState([])
  const [tailboards, setTailboards]= useState([])
  const [videos, setVideos]= useState([])
  
  const openModal=(event)=>{
    setSource(event.target.id)
    setTimeout(()=>{setOpen(true)
    }, 200)
  }

    useEffect(()=>{
      orderedQuery("tailboards", "jobID", jobsId, "dateAdded", "asc", setTailboards)
      orderedQuery("jobVideos", "JobId", jobsId, "Date", "asc", setVideos)
      fetchOne("jobs", `${jobsId}`, setDetails)
      fetchOne("customers", `${details.CustID}`, setCustomer)
    }, [])
      
  return (
    <>
    {/* <button className='bg-green-400 rounded text-white p-2' onClick={()=>console.log(selectedVideo)}>Get The Deets</button> */}
      <div className="h-full flex">
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
      {open==true ? <Modal closed={setOpen} source={source}/> : 
              <article>
                {/* Profile header */}
                <div>
                  <div className='m-4'>
                    <p className='text-2xl'>Work Order: {details.WorkOrder}</p>
                    <h3 className='text-1xl'>{customer.Name}</h3>

                  </div>
                </div>
                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <p
                            onClick={()=>setSelectedTab(tab.name)}
                            id={tab.name}
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-pink-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover: cursor-pointer text-gray-700 hover:border-gray-300',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </p>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                {selectedTab=="Site Videos" && videos.length!==0 ?
                <div className='flex'>
                <div className="p-8">
                {videos.map((link, v) => (
                  <>
                  <div
                  key={v}
                  className="flex flex-shrink-0 rounded-lg border border-gray-300 bg-white px-6 py-5 mt-4 shadow-sm min-h-fit items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
                  onClick={()=>setSelectedVideo(videos[v])}                  >
                    <div className="flex-1 min-w-0">
                        <span className="inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">Date: {link.item.Date.toDate().toDateString()}</p>
                        <p className="text-sm font-medium text-gray-900">Status: {link.item.Status}</p>
                        <p className="text-sm text-orange-600 truncate">See Video ></p>
                    </div>
                  </div>
                  </>
                    ))}
                  </div>
                  <div className="border-l-2 pl-2 w-2/3 h-screen flex justify-center shrink-0">
                    {selectedVideo.item ?
                    <video className="max-h-96 ml-4" src={selectedVideo.item.JobVideoUrl} controls></video>
                    :
                    <video className="max-h-96 ml-4" src={videos[0].item.JobVideoUrl} controls></video>
                    }
                  </div>
                  </div>

                :
                selectedTab=="Site Videos" && videos.length==0 ?
                <div className="text-center">
                <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Site Videos</h3>
      <p className="mt-1 text-sm text-gray-500">Site videos can be added to this Job using our VPS Field App</p>
      <div className="mt-6">
        <button
          type="button"
          // onClick={()=>setOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <DeviceMobileIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
          Get The Mobile App
        </button>
      </div>
    </div>
                :
                selectedTab=="Tailboards" && tailboards.length!==0 ?
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-8">
                {tailboards.map((link, t) => (
                  <div
                    key={t}
                    className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-600"
                  >
                    <div className="flex-shrink-0">
                    </div>
                    <div className="flex-1 min-w-0">
                      <a href={link.item.fileRef} target="_blank" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">Date: {link.item.dateAdded.toDate().toDateString()}</p>
                        <p className="text-sm text-orange-600 truncate">View Tailboard ></p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>

                :
                selectedTab=="Tailboards" && tailboards.length==0 ?
                <div className="text-center">
                <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Tailboards</h3>
      <p className="mt-1 text-sm text-gray-500">Tailboards can be added to this Job using our VPS Field App</p>
      <div className="mt-6">
        <button
          type="button"
          // onClick={()=>setOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <DeviceMobileIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
          Get The Mobile App
        </button>
      </div>
    </div>
                :
                selectedTab=="Details" && details.length!==0?
                <div className='max-w-md p-3 flex-col items-start rounded mt-3'>
                  <div className="ml-5">
                    <div className='flex-col text-center justify-center max-h-lg max-w-xl mt-1.5 px-4 py-2 rounded'>
                          <div className='flex-col text-start justify-center'>
                          <h4 className='mb-3'>Feeder: {details.Feeder}</h4>
                          <h4 className='mb-3'>Fuse: {details.Fuse}</h4>
                          <h4 className='mb-3'>Location: {details.Location}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                :
                selectedTab=="Details" && details.length==0 ?
                <div className='max-w-md p-3 flex-col items-start rounded mt-3'>
                <p>Sorry, no Details are currently attached to this job</p>
                </div>
                :<span></span>
                }
              </article>
}
            </main>
        
          </div>
        </div>
      </div>
    </>
  )
}