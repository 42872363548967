import React, {useEffect, useState } from 'react';
import sidebarLogo from '../assets/sidebarLogo.svg';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../src/Firebase";


function SignUp() {
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [name, setName] = useState("");


  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, signupEmail, signupPassword);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
        <div className="min-h-screen flex flex-col justify-center py-12 bg-neutral-900 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-36 w-auto"
              src={sidebarLogo}
              alt="VPS"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-neutral-50">Sign Up</h2>
           
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md m-4">
            <div className="bg-neutral-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
             
              {/* form below */}
              <div className="space-y-6 m-4">
               
          
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-neutral-50">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="Name"
                      name="Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
               
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-50">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-neutral-50">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-neutral-50">
                      Remember me
                    </label>
                  </div>
  
                  <div className="text-sm">
                    <a href="/signin" className="font-medium text-orange-600 hover:text-gray-900">
                      Already a User?
                    </a>
                  </div>
                </div>
  
                <div>
                  <button
                    onClick={register}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
                 {/*form above*/}

            </div>
          </div>
        </div>
      </>
    );
 }

      export default SignUp;