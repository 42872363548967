import { useState, useEffect, Fragment } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, orderedQuery, usersFromGroups, submitTask, uploadPic, fetchAllTagged, newPolicy, db, fetchOne, fetchOneReturn } from "../../Firebase"
import { addDoc, collection, Timestamp } from "firebase/firestore"
import { ArrowUpIcon, ArrowDownIcon, XIcon } from "@heroicons/react/outline"
import { getDownloadURL } from "firebase/storage"
import DecisionModal from "../../components/DecisionModal"

export default function PolicyInput({open, policyBank}){

const [user]= useAuthState(auth)
const [assignee, setAssignee]=useState([])
const [assigneeList, setAssigneeList]=useState([])
const [policyForm, setPolicyForm]= useState({})
const [users, setUsers]= useState([])
const [group, setGroup]= useState([])
const [admin, setAdmin]= useState([])
const [linemen, setLinemen]= useState([])
const [drillers, setDrillers]=useState([])
const [listView, setListView]=useState(false)
const [attachment, setAttachment]= useState(null)
const [attachmentFileName, setAttachmentFileName]= useState("")
const [attachmentUrl, setAttachmentUrl]= useState("")
const [errorMessage, setErrorMessage]= useState(false)
const [success, setSuccess]= useState(false)
const [decision, setDecision]= useState(false)


const assignIndividual=(name, id, op, index)=>{
    var individual=document.getElementById(name)
    var assignees=[...assignee]
    var assigneesList=[...assigneeList]
    
    if(op=="add" && assignees.includes(id)){
        if(individual.checked==true){
            return
        }
        else if(individual.checked==false){
            assignees.pop(id)
            assigneesList.pop(name)
        }
    }
    else if(op=="add"){
        assignees.push(id)
        assigneesList.push(name)
    }
    else if(op=="remove"){
        assignees.splice(index, 1)
        assigneesList.splice(index, 1)
        if(individual){
            individual.checked=false
        }
    }
    setAssigneeList(assigneesList)
    setAssignee(assignees)
}

const checkAll=(event)=>{
    var drillTeam= document.getElementById("All Drill Team")
    var admins= document.getElementById("All Admin")
    var lineWorkers= document.getElementById("All Line Workers")
    var team= document.getElementById("All Team Members")

    if(event.target.checked===true){
        if(event.target.value==="All Drill Team"){
            setGroup([...group, event.target.value])
            usersFromGroups(group, setAssignee, setAssigneeList)
        }
        else if(event.target.value==="All Admin"){
            setGroup([...group, event.target.value])
            usersFromGroups(group, setAssignee, setAssigneeList)
        }
        else if(event.target.value==="All Line Workers"){
            setGroup([...group, event.target.value])
            usersFromGroups(group, setAssignee, setAssigneeList)
        }
        else if(event.target.value==="All Team Members"){   
            drillTeam.checked=true
            admins.checked=true
            lineWorkers.checked=true           
            setGroup([event.target.value])
            usersFromGroups(group, setAssignee, setAssigneeList)
        }
    }
    else{
        drillTeam.checked=false
        admins.checked=false
        lineWorkers.checked=false
        team.checked=false
        const tags= [...group]
        tags.pop(event.target.value)
        setGroup([...tags])
        setAssignee([])
        setAssigneeList([])
    }
}

const dateChange=(event)=>{
    let dateString= event.target.value
    let date= new Date(dateString)
    let dateStamp= Timestamp.fromDate(date)
    let time= {
        seconds:dateStamp.seconds+18000
    }
    const final= new Timestamp(time.seconds, 0)
    setPolicyForm({
        ...policyForm,
        [event.target.name]:final
    })
}

const updatePolicyForm=(e)=>{
    fetchOneReturn("policyBank", e.target.value)
    .then((res)=>{
        setPolicyForm({
            ...policyForm,
            "attachment": res.attachment,
            "attachmentFileName": res.attachmentName,
            "title":res.title,
            "number":res.policyNumber
        })
        console.log("Form Updated")
    })
}

const submitToGroup=async()=>{
    try{
        if(policyForm.title!==undefined && assigneeList.length>0){

            for(var i=0; i<assignee.length; i++){
                await newPolicy({
                    title: policyForm.title,
                    number: policyForm.number,
                    date: Timestamp.now(),
                    attachment:policyForm.attachment,
                    attachmentFileName:policyForm.attachmentFileName,
                    acknowledged:false,
                    assignee: assignee[i]
                })
                .then((policy)=>{
                    addDoc(collection(db, "tasks"),
                    {
                        AssignedTo: assignee[i],
                        Attachment: policyForm.attachment,
                        AttachmentFileName: policyForm.attachmentFileName,
                        CreatedBy:user.uid,
                        Description: policyForm.title,
                        DueDate: Timestamp.now(),
                        Completed: false,
                        CompleteDate: "",
                        Subject: "New Policy",
                        PolicyId: policy,
                        Type: "Policy"
                    })
                })
            }
            setErrorMessage(null)
            setSuccess("Policy Assigned!")
            setAssignee([])
            setAssigneeList([])
            setTimeout(()=>{
                setSuccess(false)
            }, 3000)
        }
        else{
            setErrorMessage("You have left out a field. Please also make sure the policy is assigned to at least one person")
        }
        }
    catch(err){
        console.log(err)
    }
}

useEffect(()=>{
    fetchAllTagged("users", "lName", setUsers)
    usersFromGroups(group, setAssignee, setAssigneeList)
    orderedQuery("lu_user_type", "category", "Drill Team", "title", "asc", setDrillers)
    orderedQuery("lu_user_type", "category", "Admin", "title", "asc", setAdmin)
    orderedQuery("lu_user_type", "category", "Line Workers", "title", "asc", setLinemen)
},[group])

    return(
        <div className='max-w-xl'>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="flex flex-col w-full">
                    <div className="my-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Policy to Be Assigned
                        </label>
                        <select className="border rounded-lg w-full" onChange={(e)=>updatePolicyForm(e)}>
                            <option></option>
                            {policyBank.map((policy, i)=>{
                                return(
                                    <Fragment key={i}>
                                        <option value={policy.id} onSelect={(e)=>updatePolicyForm(e)}>{policy.item.title}</option>
                                    </Fragment>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                            Assign Groups:
                        </label>
                    </div>
                    <div className="flex flex-row">
                        <div>
                            <input value="All Team Members" id="All Team Members" type="checkbox" onClick={(e)=>checkAll(e)}/>
                            <label className="p-2">All Team Members</label>
                        </div>
                        <div className="">
                            <input value="All Line Workers" id='All Line Workers' type="checkbox" onClick={(e)=>checkAll(e)}/>
                            <label className="p-2">All Line Workers</label>
                        </div>
                        <div className="">
                            <input value="All Admin" id='All Admin' type="checkbox" onClick={(e)=>checkAll(e)}/>
                            <label className="p-2">All Admin</label>
                        </div>
                        <div className="">
                            <input value="All Drill Team" id='All Drill Team' type="checkbox" onClick={(e)=>checkAll(e)}/>
                            <label className="p-2">All Drill Team</label>
                        </div>
                    </div>
                </div>

                {/*Attached to Group*/}
                <label htmlFor="individual" className="my-3 block text-sm font-medium text-gray-700"> Assign Individual:</label>
                <div className="flex flex-row w-100% border">
                    <div className="flex justify-start align-middle">
                        {listView ?
                        <div className="flex justify-end align-middle h-full w-7 border" onClick={()=>setListView(false)}>
                            <ArrowUpIcon/>
                        </div>   
                        :
                        <div className="flex justify-end align-middle h-full w-7 border" onClick={()=>setListView(true)}>
                            <ArrowDownIcon/>
                        </div>
                        }
                    </div>
                    <div className="flex flex-row overflow-x-scroll">
                    {assigneeList.length!==0 &&
                        assigneeList.map((member, i)=>{
                            return(
                                <div className="border bg-gray-50 rounded-lg mx-2 min-w-fit flex flex-row" key={i}>
                                    <div className="w-fit flex flex-grow-0 justify-center align-middle pl-2">
                                    <p>{member}</p>
                                    </div>
                                    <div className="ml-2 flex">
                                    <XIcon className="hover:cursor-pointer h-4 w-4 rounded-full bg-gray-100 border border-gray-200" onClick={()=>assignIndividual(member, assignee[i], "remove", i)}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>

                {/*People to be Assigned*/}
                {listView ?
                <div className="h-40 overflow-scroll w-100% flex flex-col border p-2">
                    {users.map((user, i)=>{
                        return(
                            <div className="flex" key={i}>
                            <input id={`${user.item.fName} ${user.item.lName}`} onChange={(e)=>assignIndividual(e.target.id, user.item.uid, 'add', i)} type='checkbox'/>
                            <label className="ml-2">{`${user.item.fName} ${user.item.lName}`}</label>
                            </div>
                            )
                        })}
                </div>
                :
                <div></div>
                }
            </div>
            
            <div className='col-span-3 sm:col-span-3 mt-5'>
            <button className='bg-orange-600 text-white p-2 h-12 rounded' onClick={()=>submitToGroup()}>Assign Policy</button>
            </div>
            {success &&
                <div className="flex justify-center p-5 rounded-lg mt-3 bg-indigo-700">
                    <h1 className="text-white text-xl">{success}</h1>    
                </div>}
            {errorMessage &&
            <div className="flex justify-center p-5 rounded-lg mt-3">
                <h1 className="text-red-500 text-xl">{errorMessage}</h1>    
            </div>}
        </div>    
    )
}