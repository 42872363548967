import { useEffect, useState } from "react"
import { uploadPic } from "../Firebase"

export default function ImageUpload({form, setForm, bucket}){

    const [attachment, setAttachment]= useState(null)
    const [errorMessage, setErrorMessage]= useState(null)
    const [url, setUrl]= useState('')

    const newPic= async (event)=>{
        setErrorMessage(null)
        if(event.target.files[0].size>10000000){
        setErrorMessage('This file is too big. Please choose one under 10MB')
        return
        }
        else if(event.target.files[0].type!== "application/pdf" && 
                event.target.files[0].type!== "image/jpeg" &&
                event.target.files[0].type!== "image/png"){
        setErrorMessage('Please choose a supported file type. Ex: PDF, PNG, JPEG')
        return
        }
        else{
        setErrorMessage(null)
        uploadPic(bucket, attachment, attachment.name, setUrl)
        .then((url)=>{
            setForm({...form, ["attachmentName"]:event.target.files[0].name})
        })
    }
}

useEffect(()=>{
    setForm({...form, ["attachment"]:url})
}, [url])


    return(
        <div className="w-full">
            <div className="sm:max-w-sm md:max-w-medium flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    </svg>
                    <div className="flex text-sm justify-center text-gray-600">
                    <label
                        htmlFor="attachment"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                    <span>Upload a file</span>
                    <input id="attachment" type="file" name="attachment" className="sr-only" onInput={(e)=>setAttachment(e.target.files[0])} onChange={(e)=>newPic(e)}/>
                    </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>
            </div>
        </div>
    )
}