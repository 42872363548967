export default function DecisionModal({engage, open}){

    const engageAndClose=()=>{
        engage()
        open(false)
    }

return(
    <div className="flex justify-center align-middle absolute h-full w-full bg-gray-700 bg-opacity-50">
            <div className="flex flex-col justify-center absolute top-1/2 border-2 border-gray-400 rounded-lg bg-slate-50 h-36 w-56">
                <div className="flex justify-center h-1/3">
                    <h1>Are You Sure?</h1>
                </div>
                <div className="flex flex-row justify-evenly">
                    <div className="bg-green-500 flex justify-center p-2 rounded-lg w-1/3" onClick={()=>engageAndClose()}>
                        <p className="text-white">Yes</p>
                    </div>
                    <div className="bg-red-500 flex justify-center p-2 rounded-lg w-1/3" onClick={()=>open(false)}>
                        <p className="text-white">No</p>
                    </div>
                </div>
            </div>
    </div>
)
}