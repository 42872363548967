import { Fragment, useEffect, useState } from "react"
import { Polygon } from "recharts"
import { fetchAll, lastThirty, orderedQuery, retrieveAssignees, retrieveUsernames } from "../Firebase"
import PolicyBankInput from "./PoliciesComp/PolicyBankInput"
import PolicyDisplay from "./PoliciesComp/PolicyDisplay"
import PolicyInput from "./PoliciesComp/PolicyInput"

export default function Policies(){

const [policies, setPolicies]=useState([])
const [policyBank, setPolicyBank]= useState([])
const [policyBankLapsed, setPolicyBankLapsed]=useState([])
const [policyInput, setPolicyInput]= useState()
const [checkedPolicies, setCheckedPolicies]= useState([])
const [users, setUsers]= useState([])
const [received, setReceived]= useState(false)
const [usersIncomplete, setUsersIncomplete]= useState([])
const [usersComplete, setUsersComplete]=useState([])
const [sideDisplay, setSideDisplay]= useState(false)
const [selectedPolicy, setSelectedPolicy]= useState(null)
const [selectedTab, setSelectedTab]= useState(["Policy Bank"])
const [deposit, setDeposit]= useState(false)
const [attachment, setAttachment]= useState(null)
const [attachmentFileName, setAttachmentFileName]= useState("")
const [attachmentUrl, setAttachmentUrl]= useState("")

const tabs = [
  { name: 'Policy Bank'},
  { name: 'Assign Policy'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

//Open Side Display and Change Selected Policy
const openDisplay=(tag, list)=>{
    if(list.length === 0){
      orderedQuery("policyBank", "inEffect", true, "pubDate", "asc", setPolicyBank)
        .then((result)=>{
            result.length===0?
            setSelectedPolicy(null)
            :
            setSelectedPolicy(result[tag])
        })
        return
    }
    else{
        setSelectedPolicy(list[tag])
        return
    }
}

const retrieveUsers=async(list, setter)=>{
  if(list.length==0){
    setTimeout(()=>{
      retrieveUsers(list, setter)
    }, 200)
  }
  else{
    retrieveAssignees(list)
    .then((users)=>{
      retrieveUsernames(users)
      .then((usernames)=>{
        setter(usernames)
        })
      })
  }
}

//Set the Policies in state
const fetchPolicies=()=>{
    orderedQuery("policyBank", "inEffect", true, "pubDate", "asc", setPolicyBank)
    orderedQuery("policyBank", "inEffect", false, "pubDate", "asc", setPolicyBankLapsed)
}

useEffect(()=>{
    openDisplay(0, policyBank)
    fetchPolicies()
},[deposit])

return(
<>
<div className="px-4 sm:px-6 lg:px-8">
    <div className="flex flex-col">
    <h1 className="text-xl font-semibold text-gray-900">Policies</h1>
      {selectedTab=="Policy Bank" && selectedPolicy!==null && selectedPolicy!==undefined?
        <PolicyDisplay info={selectedPolicy} fetch={fetchPolicies} selected={setSelectedPolicy}/>
      :
      <></>
  }
    {deposit==true?
    <div></div>
    :
    <div></div>
    }
    <nav className="-mb-px flex space-x-8">
    {tabs.map((tab) => (
                          <a
                            onClick={()=>setSelectedTab(tab.name)}
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-pink-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover: cursor-pointer text-gray-700 hover:border-gray-300',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </a>
                        ))}
    </nav>
    {selectedTab == "Policy Bank"?
                <div className="mt-5 border-t">
              {deposit==true ?
              <PolicyBankInput open={setDeposit}/>
              :
              <>
              <div>
                <button className="bg-orange-500 text-white rounded-lg p-2 mt-3" onClick={()=>setDeposit(true)}>Add to Policy Bank</button>
              </div>
                <h1 className="mt-6">Active Policies</h1>
                {/* <button className="bg-green-400 p-2 text-white rounded" onClick={()=>console.log(policyBank)}>Get the Deets</button> */}
                <div className="-mx-4 mt-2 overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg sm:w-2/3 max-w-fit">
                  <table className="divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Title</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Policy Number</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Pub Date</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Effective Date</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {policyBank.map((policy, i)=>{
                        return(
                          <tr className="hover:cursor-pointer hover:bg-gray-100 bg-white-200 hover: cursor-pointer" onClick={()=> openDisplay(i, policyBank)} key={i}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell min-w-[250px]">
                          {policy.item.title}                        
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.policyNumber}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.pubDate &&
                          policy.item.pubDate.toDate().toDateString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.effectiveDate &&
                          policy.item.effectiveDate.toDate().toDateString()}
                        </td>
                      </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <h1 className="mt-6">Archived Policies</h1>
                <div className="-mx-4 mt-2 overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg sm:w-2/3 max-w-fit">
                  <table className="divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Title</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Policy Number</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Pub Date</th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell">Effective Date</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {policyBankLapsed.map((policy, i)=>{
                        return(
                          <tr className="hover:cursor-pointer hover:bg-gray-100 bg-white-200 hover: cursor-pointer" onClick={()=> openDisplay(i, policyBankLapsed)} key={i}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell min-w-[250px]">
                          {policy.item.title}                        
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.policyNumber}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.pubDate &&
                          policy.item.pubDate.toDate().toDateString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 sm:table-cell">
                          {policy.item.effectiveDate &&
                          policy.item.effectiveDate.toDate().toDateString()}
                        </td>
                      </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  </div>
                </>
                }
                </div>
      :
      <div className="pt-5 border-t mt-5 w-fit">
        <PolicyInput policyBank={policyBank}/>
      </div>
      }
    </div>
      </div>
</>
)}