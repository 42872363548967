import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,} from "firebase/auth";
import {
    getFirestore,
    query,
    where,
    collection,
    getDocs,
    getDoc,
    addDoc,
    setDoc,
    doc,
    orderBy,
    Timestamp,
    collectionGroup,
    limitToLast,} from "firebase/firestore";
import {
    getStorage, 
    ref, 
    uploadBytes, 
    getDownloadURL} from 'firebase/storage';



// Database Credentials
const firebaseConfig = {
  apiKey: "AIzaSyBYVB8tMaTWVFcuKlLYOWVXCuWtFED-9UM",
  authDomain: "voltage-power-solutions.firebaseapp.com",
  databaseURL: "https://voltage-power-solutions-default-rtdb.firebaseio.com",
  projectId: "voltage-power-solutions",
  storageBucket: "voltage-power-solutions.appspot.com",
  messagingSenderId: "1094844737779",
  appId: "1:1094844737779:web:40c97f8cf82d7efae26f86",
  measurementId: "G-WTEG8XP4SX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
// const analytics = getAnalytics(app);


//Hooks
const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };


const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };


  const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };


  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };


  const logout = () => {
    signOut(auth);
  };


  const getDocId= async(col, field, did)=>{
    try{
    const q=(query(collection(db, col), where(field, "==", did)))
    const doc= await getDocs(q)
    const docPath= doc.docs[0].id
    return docPath
  }
  catch(err){
    console.log(`getId error: ${err}`)
  }
  }

  function formatBirthday(string){
    const Month={
      "Jan":"01",
      "Feb":"02",
      "Mar":"03", 
      "Apr":"04",
      "May":"05",
      "Jun":"06",
      "Jul":"07",
      "Aug":"08",
      "Sep":"09",
      "Oct":"10",
      "Nov":"11",
      "Dec":"12"
    }
    const format= `${string.slice(7,11)}-${Month[string.slice(0,3)]}-${string.slice(4,6)}`
    return format
  }

  const fetchProfile= async (field, id, setter)=>{
    try{
        const q= query(collection(db, "users"), where(field, "==", id));
        const doc= await getDocs(q)
        const data= doc.docs[0].data()
        if(data.birthday && data.birthday!==undefined|String){
          const birthDate= data.birthday.toDate()
          const birthDay= birthDate.toDateString()
          const birthString= birthDay.slice(4)
          const birthFormat= formatBirthday(birthString) 
          setter({
              fName:data.fName,
              lName:data.lName,
              email:data.email,
              phoneNumber:data.phoneNumber,
              birthday: birthString,
              birthDate: birthFormat,
              profilePicUrl:data.profilePicUrl
          })
        }
        else{
          setter(data)
        }
    }
    catch(err){
        console.log(`profile error: ${err}`);
    }
  };


  const fetchOne= async(table, item, setter)=>{
    try{
      const docRef= doc(db, table, item)
      const data= await getDoc(docRef)
      if(data.exists()){
        setter(data.data())
        console.log("arf")
      }
    }
    catch(err){
      console.log(err)
      alert(err)
    }
  }

  const fetchOneReturn= async(table, item)=>{
    try{
      const docRef= doc(db, table, item)
      const data= await getDoc(docRef)
      if(data.exists()){
        return(data.data())
      }
    }
    catch(err){
      console.log(err)
      alert(err)
    }
  }

  const fetchOneTagged= async(table, item, setter)=>{
    try{
      const document= []
      const docRef= doc(db, table, item)
      const data= await getDoc(docRef)
      if(data.exists()){
        document.push({id:data.id, item:data.data()})
        setter(document)
        console.log("arf")
        return data
      }
    }
    catch(err){
      console.log(err)
      alert(err)
    }
  }

  const fetchItemsMF= async(table, field1, value1, field2, value2)=>{
    try{
      const result=[]
      const q=query(collection(db, table), where(field1, "==", value1), where(field2, "==", value2))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>{
        result.push({id: doc.id, item:doc.data()})
      })
      return result
      }
      catch(err){
        alert(err)
      }
    }

  const fetchMultiple= async (table, field, item)=>{
    try{
      const list=[]
      const q= query(collection(db, table), where(field, "==", item))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id, item:doc.data()})
      )
      console.log("woof woof")
      return list
    }
    catch(err){
      console.log(`list multiple error: ${err}`)
      alert(err)
    }
  }

  const fetchMultipleTagged= async (table, field, item, setter)=>{
    try{
      const list=[]
      const q= query(collection(db, table), where(field, "==", item))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id, item:doc.data()})
      )
      setter(list)
      console.log("target acquired")
      return list
    }
    catch(err){
      console.log(`list multiple error: ${err}`)
      alert(err)
    }
  }

  const getComments=async(table, item, setter)=>{
    try{
      const list=[]
      const q=query(collection(db, table, item, "comments"), orderBy('Created_at', 'desc'))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push(doc.data())
      )
      setter(list)
      console.log("messages acquired")
      return list
    }
    catch(err){
      console.log(`list multiple error: ${err}`)
      alert(err)
    }
  }

  const getTasks=async(assignee, boolean, setter)=>{
    try{
      const list=[]
      const q= query(collection(db, "tasks"), where("AssignedTo", "==", assignee), where ("Completed", "==", boolean), orderBy("DueDate", 'asc'))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id, item:doc.data()})
      )
      setter(list)
      console.log("tasks found")
      return list
    }
    catch(err){
      console.log(`task fetching errors ${err}`)
    }
  }

  const orderedQuery=async (col, field, value, ord, dir, setter)=>{
    try{
      const list=[]
      const q= query(collection(db, col), where (field, "==", value), orderBy(ord, dir))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id, item:doc.data()})
      
      )
      setter(list)
      console.log("items ordered")
      return list
    }
    catch(err){
      console.log(`ordining errors ${err}`)
    }
  }

  const orderedQueryM=async (col, field1, value1, field2, value2, ord, dir, setter)=>{
    try{
      const list=[]
      const q= query(collection(db, col), where (field1, "==", value1), where(field2, "==", value2), orderBy(ord, dir))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id, item:doc.data()})
      
      )
      setter(list)
      console.log("items ordered")
      return list
    }
    catch(err){
      console.log(`ordining errors ${err}`)
    }
  }

  const getGroupTasks=async(groups, boolean, setter)=>{
    const list=[]
    const q=query(collection(db, "tasks"), where("AssignedTo", "array-contains-any", groups), where("Completed", "==", boolean), orderBy("DueDate", 'asc'))
    const snapshot=await getDocs(q)
    snapshot.forEach((doc)=>
    list.push({id:doc.id, item:doc.data()})
    )
    setter(list)
    return list
  }


  const fetchAll= async (table, setter)=>{
    try{
      const list= []
      const ref= (collection(db, table))
      const snapshot= await getDocs(ref)
      snapshot.forEach((doc)=>
      list.push(doc.data())
      )
      console.log("Tools, gears, coats...")
      setter(list)
      return list
    }
    catch(err){
      console.log(`list all error: ${err}`)
      alert(err)
    }
  }

  const fetchAllTagged= async (table, order, setter)=>{
    try{
      const list= []
      const q= query(collection(db, table), orderBy(order, 'desc'))
      const snapshot= await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id:doc.id ,item:doc.data()})
      )
      console.log("Tools, gears, coats...")
      setter(list)
      return list
    }
    catch(err){
      console.log(`list all error: ${err}`)
      alert(err)
    }
  }

  const uploadPic= async (bin, picState, reference, setter)=>{
    setTimeout(()=>{
      try{
        const storage= getStorage(app)
          const picRef= ref(storage, `${bin}/${reference}`)
          uploadBytes(picRef, picState).then(()=>{
            getDownloadURL(picRef).then((url)=>{
              var attachmentUrl=url
              setter(url)
              return attachmentUrl
            })
            console.log("Successful Update")
          })
        }
      catch(err){
        console.log(`Pic URL Error:${err}`)
      }
    }, 200)
  }

  const addJob= async(obj)=>{
    try {
      const docRef= await addDoc(collection(db, 'jobs'),{
        CustID: obj.customerId,
        DateSubmitted: Timestamp.now(),
        Feeder: obj.feeder,
        Fuse: obj.fuse,
        Location: obj.location,
        WorkOrder: obj.workOrder
      })
    }
    catch(err){
      console.log(err)
    }
  }

  const addReceipt= async (col, obj, ru)=>{
    try{
        const docRef= await addDoc(collection(db, col),{
        amount: obj.amount,
        category:obj.expenseType,
        date:obj.date,
        description: obj.description,
        equipment: obj.equipment,
        isReimbursed: obj.isReimbursed,
        logDate: obj.logDate,
        paymentMethod: obj.paymentMethod,
        paymentType: obj.paymentType,
        receiptUrl:obj.receiptUrl,
        reimburseUser: ru,
        sageEntered: obj.sageEntered,
        vendor: obj.vendor
    });
    console.log("Receipt added")
    }
    catch(err){
    console.log(`Receipt error:${err}`)
    }
}

const addToPolicyBank= async (obj)=>{
  try{
    const docRef= await addDoc(collection(db, "policyBank"),{
      title: obj.title,
      policyNumber:obj.policyNumber,
      pubDate: Timestamp.now(),
      effectiveDate: obj.effectiveDate,
      attachment: obj.attachment,
      attachmentName: obj.attachmentName,
      inEffect: true
    });
    console.log("Deposit Made")
  }
  catch(err){
    console.log(err)
  }
}

const submitTask=async (assignee, assigner, obj, url, filename, setter)=>{
  try{
    for(var i=0; i<assignee.length; i++){
      const task= await addDoc(collection(db, "tasks"),{
      AssignedTo: assignee[i],
      Attachment: url,
      AttachmentFileName: filename,
      CreatedBy:assigner,
      Description: obj.Description,
      DueDate: obj.DueDate,
      Completed: false,
      CompleteDate: "",
      Subject: obj.Subject,
      Type: obj.Type
      })
      setter(false)
      console.log("Task Assigned")
    }
    }
    catch(err){
      alert(err)
    }
}

  const updateField= async(col, item, info)=>{
    try{
      await setDoc(doc(db, col, item), info, {merge:true})
    }
    catch(err){
      console.log(`update field err ${err}`)
    }
  }

  const getSubcollection=async(table, category, subcol)=>{
    try{
      const list=[]
      const q=query(collection(db, table, category, subcol))
      const snapshot=await getDocs(q)
      snapshot.forEach((doc)=>
      list.push({id: doc.id, item:doc.data()})
      )
      console.log("messages acquired")
      return list
    }
    catch(err){
      console.log(`list multiple error: ${err}`)
      alert(err)
    }
  }

  const usersFromGroups= async (groupsearch, setter, namer)=>{
    try{
      if(groupsearch.length===0){
        return
      }
      else{
        const list=[]
        const names=[]
        const userRef= collection(db, "users")
        const q= query((userRef), where("groups", "array-contains-any", [...groupsearch]))
        const snapshot=await getDocs(q)
        snapshot.forEach((doc)=>{
          list.push(doc.data().uid)
          names.push(`${doc.data().fName} ${doc.data().lName}`)
        })
        setter(list)
        namer(names)

        console.log("users found")
      }
    }
    catch(err){
      console.log(err)
    }
  }

  const lastThirty=async(setter, col, boo, boolean, date, userList)=>{
    try{
      const list=[]
      const users=[]
      const usernames=[]
      const today= Timestamp.now()
      const thirtyprior= new Timestamp(today.seconds-2592000, 0)
      const q= query(collection(db, col), where(boo, "==", boolean) ,where(date, ">=", thirtyprior), orderBy(date, "desc"))
        const snapshot=await getDocs(q)
        snapshot.forEach((doc)=>{
          list.push({id:doc.id, item:doc.data()})
          if(col=="policies"){
            users.push(doc.data().assignee)
          }
          else if(col=="tasks"){
            users.push(doc.data().AssignedTo)
          }
        })
        setter(list)
        for(var i=0; i<users.length; i++){
          if(users[i]=='undefined'){
            usernames.push("unknown")
          }
            const docRef= query(collection(db, "users"), where("uid", "==", users[i]))
            const data= await getDocs(docRef)
            data.forEach((doc)=>{
              usernames.push(`${doc.data().fName} ${doc.data().lName}`)
            })
        }
        userList(usernames)
        console.log("last thirty")
        return(users)
    }
    catch(err){
      console.log(err)
    }
}

const tasksAndUsers= async(boolean, setter, userList)=>{
  try{
    const list=[]
    const users=[]
    const usernames=[]
    const q= query(collection(db, "tasks"), where("Completed", "==", boolean), orderBy("DueDate", "desc"))
      const snapshot=await getDocs(q)
        snapshot.forEach((doc)=>{
          list.push({id:doc.id, item:doc.data()})
          users.push(doc.data().AssignedTo)
        })
      setter(list)
      for(var i=0; i<users.length; i++){
        if(users[i]=='undefined'){
          usernames.push("unknown")
        }
          const docRef= query(collection(db, "users"), where("uid", "==", users[i]))
          const data= await getDocs(docRef)
          if(data.empty!==true){
            data.forEach((doc)=>{
              usernames.push(`${doc.data().fName} ${doc.data().lName}`)
            })
          }
          else{
            usernames.push("Unknown")
          }
      }
      userList(usernames)
      console.log("tasks and users retrieved")
      return list
  }
  catch(err){
    console.log(err)
  }
}

const existentialQuery= async (col, field, value)=>{
  const docRef= query(collection(db, col), where(field, "==", value))
  const data= await getDocs(docRef)
  if (data.empty){
    return false
  }
  else{
    return true
  }
}

const existentialQueryMF= async (col, field1, value1, field2, value2)=>{
  const docRef= query(collection(db, col), where(field1, "==", value1), where(field2, "==", value2))
  const data= await getDocs(docRef)
  if (data.empty){
    return false
  }
  else{
    return true
  }
}

const existentialQuerySub= async(subcol, field, value)=>{
  const docRef= query(collectionGroup(db, subcol))
  const q= query(docRef, where(field, "==", value))
  const data=await getDocs(q)
  if (data.empty){
    return false
  }
  else{
    return true
  }
}

const newPolicy=async (policy)=>{
  const returnValue= []
  await addDoc(collection(db, "policies"), policy)
  .then((result)=>{
    returnValue.push(result.id)
  })
  return returnValue[0]
}

const uploadPDF= async(uri)=>{
  var xhr= new XMLHttpRequest();
  xhr.open("GET", uri, true)
  xhr.responseType="arraybuffer";
  xhr.onload= function(e){
      var arrayBufferView= new Uint8Array(this.response);
      var blob= new Blob([arrayBufferView], {type: "application/pdf"});
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl =urlCreator.createObjectURL( blob );
      return imageUrl
    }
}

const retrieveAssignees =async(list)=>{
    const assignees=[]
    var i=0
    while(i<list.length){
      assignees.push(list[i].item.assignee)
      i++
    }
    return assignees
}

const retrieveUsernames=async(list)=>{
    const usernames=[]
    var i=0
    while(i<list.length){
      const q= query(collection(db, "users"), where("uid", "==", `${list[i]}`))
      const snapshot= await getDocs(q)
      if(snapshot.empty==true){
        usernames.push("Unknown")
        i++
      }
      else{
        snapshot.forEach((user)=>{
          usernames.push(`${user.data().fName} ${user.data().lName}`)
        })
        i++
      }
    }
    return usernames
}

  // const downloadTailboard=async (url, id)=>{
  //   const filename= await fetchOneReturn("jobs", jobsId)
  //   .then((res)=>{
  //     var date=new Date
  //     var year= date.getFullYear().toString()
  //     var month= date.getMonth<11 ? "0"+(date.getMonth()+1).toString() : (date.getMonth()+1).toString()
  //     var day= date.getDate<11 ? "0"+(date.getDate()+1).toString() : (date.getDate()+1).toString()
  //     return `${year}_${month}_${day}_Tailboard_${res.Feeder}_${res.Fuse}_${res.Location}`
  //   })
  //   fetch(url, {method:"GET", mode:"no-cors", referrerPolicy:"no-referrer"})
  //   .then((res)=> res.blob())
  //   .then((res)=>{
  //   const xhr= new XMLHttpRequest()
  //   xhr.open("GET", url)
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
  //   xhr.setRequestHeader("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Access-Control-Allow-Origin");
  //   xhr.responseType="arraybuffer"
  //   xhr.onload=(e)=>{
  //     const arraybuffer= e.arrayBuffer()
  //     console.log(arraybuffer)
  //     const blob= new Blob([arraybuffer], {type:"application/pdf"})
  //       const anchor= document.createElement("a")
  //       const href=URL.createObjectURL(blob)
  //       anchor.href=href
  //       anchor.setAttribute("download", filename)
  //       anchor.click()
  //       URL.revokeObjectURL(href);
  //   }
  //   xhr.send()
  //   })
  // }

  export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    fetchProfile,
    fetchOne,
    fetchOneReturn,
    fetchOneTagged,
    fetchItemsMF,
    fetchMultiple,
    fetchMultipleTagged,
    fetchAll,
    fetchAllTagged,
    getDocId,
    uploadPic,
    uploadPDF,
    updateField,
    addJob,
    addReceipt,
    addToPolicyBank,
    submitTask,
    getComments,
    getTasks,
    getSubcollection,
    getGroupTasks,
    usersFromGroups,
    lastThirty,
    orderedQuery,
    orderedQueryM,
    tasksAndUsers,
    existentialQuery,
    existentialQueryMF,
    existentialQuerySub,
    retrieveAssignees,
    retrieveUsernames,
    newPolicy
  };