import { useState, useEffect, useRef } from "react"
import { auth, fetchMultiple, updateField, uploadPic, fetchOneReturn, getDocId, fetchOneTagged, uploadPDF } from "../../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { DocumentIcon } from "@heroicons/react/solid"
import VPSFallbackNoImg from '../../assets/VPSFallbackNoImg.svg'
import { useParams } from "react-router-dom";
import DecisionModal from "../../components/DecisionModal";

export default function PolicyDisplay({ info, fetch, selected }){

  const [decision, setDecision]= useState(false)
  
  //render PDFs
  const PDFRender=async (uri)=>{
    console.log(uri)
    uploadPDF(uri)
    .then((url)=>{
      console.log(url)
      setDisplayURL(url)
    })
  }
  
  //Upload Picture to Storage
  const newPic= ()=>{
    uploadPic("policy-attachments", attachment, attachment.name, setAttachmentUrl)
    }
    
    //Upload new picture to selected policy
    const attachPhoto=(event)=>{
      try{
        const id=event.target.id
        const image= {attachment:attachmentUrl}
        updateField("policies", id, image)
        setAttachmentUrl("")
        console.log("Image Uploaded")
        fetchOneReturn("policies", info.id)
        .then((result)=>{
          setTimeout(()=>{
            fetch()
            selected({id: info.id, item:result})
          }, 500)
        })
      }
      catch(err){
        alert(err)
      }
    }
    
    //Fetch users assigned to acknowledge a policy
    const policyUsers=async ()=>{
      const list=[]
      if(info.item.assignee){
        await fetchMultiple("users", "uid", info.item.assignee)
        .then((user)=>{
          list.push(`${user[0].item.fName} ${user[0].item.lName}`)
        })
        setAssignee([list])
      }
    }
    
    //Acknowledge a Policy
    const archivePolicy=()=>{
      if(info.item.inEffect == true){
        updateField("policyBank", info.id, {inEffect:false})
      }
      else{
        updateField("policyBank", info.id, {inEffect:true})
      }
      setTimeout(()=>{
        fetch()
        fetchOneReturn("policyBank", info.id)
        .then((result)=>{
          selected({id: info.id, item:result})
        })
      }, 500)
    }
    
    //Scroll to the top of Display
    const backToTop=()=>{
      displayRef.current.scrollTo(
        {top:0,
          left:0,
          behavior:"smooth"
        })
        console.log("up up and away")
      }
      
      const displayRef= useRef(null);
      const [user]= useAuthState(auth)
      const {member}= useParams()
      const [attachment, setAttachment]= useState(null)
      const [attachmentUrl, setAttachmentUrl]= useState("")
      const [assignee, setAssignee]= useState([])
      const [assignerInfo, setAssignerInfo]= useState({})
      const [displayURL, setDisplayURL]= useState("")
      // const searchfile= info.item.attachmentFileName== "" ? "" : info.item.attachmentFileName.split('.')
      // const filetype= searchfile[1]
      
      useEffect(()=>{
        backToTop()
        policyUsers()
    }, [info])

    // function ViewAttachmentImage() {
    //   window.location.href = '{info.item.Attachment}';
    //   return null;
    // }


return(
<>
  <div className="flex flex-col fixed top-12 right-0 bg-white overflow-y-scroll h-full w-1/3 border-l border-l-gray-200" ref={displayRef}>
{decision && <DecisionModal engage={archivePolicy} open={setDecision}/>}
            <div className="mt-2 max-w-8xl mx-auto px-4 sm:px-6">
              <div className="relative text-gray-400 focus-within:text-gray-500">
              </div>
            </div>
            <div className="max-w-8xl mx-auto py-1 px-2 sm:px-4">

            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                </div>
                <div className="ml-3 min-w-0 flex-1">
                {info.item.attachment!=="" ?
                    <>
                      <embed className="h-64 w-64 --tw-scale-x-50 --tw-scale-y-50 origin-top" src={info.item.attachment}/>
                    </>
                    :
                    <>
                    <img src={VPSFallbackNoImg} className="h-64"/>
                    </>
                    }
                <div className="text-base font-medium text-gray-800 truncate border-b-2 py-2">Title:
                {info.item.title &&
                <p className="ml-2">
                {info.item.title}
                </p> 
                }
                </div>
                <div className="text-base font-medium text-gray-800 truncate border-b-2 py-2">Policy Number:
                {info.item.policyNumber &&
                <p className="ml-2">
                {info.item.policyNumber}
                </p> 
                }
                </div>
                <div className="text-base font-medium text-gray-800 truncate border-b-2 py-2">Published: 
                {info.item.pubDate &&
                <p className="ml-2">
                  {info.item.pubDate.toDate().toDateString().slice(4)}
                </p>
                }
                </div>
                {info.item.inEffect== true &&
                <>
                <div className="text-base font-medium text-gray-800 truncate border-b-2 py-2">In Effect Since: 
                {info.item.effectiveDate &&
                <p className="ml-2">
                  {info.item.effectiveDate.toDate().toDateString().slice(4)}
                </p>
                }
                </div>
                <div className="text-base font-medium text-gray-800 truncate border-b-2 py-2">
                <button className="ml-2 text-white bg-red-500 hover: cursor-pointer p-2 rounded-lg" onClick={()=>setDecision(true)}>
                Archive Policy
                </button>
                </div>
                </>
                }

                </div>
              </div>
              <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
              </div>
            </div>
        </div>
</>
)}
