import { Fragment, useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { auth, db, fetchProfile, existentialQuery, existentialQuerySub } from "../../src/Firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  LightningBoltIcon,
  TruckIcon,
  ReceiptRefundIcon,
  OfficeBuildingIcon,
  CheckCircleIcon,
  ArchiveIcon
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import sidebarLogo from '../assets/sidebarLogo.svg';
import { logout } from '../Firebase';
import fallbackAvatar from '../assets/VPSFallbackAvatar.svg'
import { list } from 'firebase/storage';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar(screen) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [user, loading, error] = useAuthState(auth);
  const [fetched, setFetched]=useState(false)
  const [unread, setUnread]=useState({})
  const [unimbursed, setUnimbursed]=useState({})
  const [notifications, setNotifications]= useState([])
  const [viewNotifications, setViewNotifications]= useState(null)
  const [name, setName] = useState("");
  const [profile, setProfile]= useState({})
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const fetchNotifications=async()=>{
    const list=[]
    await existentialQuery("receipts", "isReimbursed", false)
    .then((value)=>{
      if(value==true){
        list.push({message:`You have receipts to be reimbursed`, href:"/receipts"})
      }
    })
    await existentialQuerySub("comments", "Read", false)
    .then((value)=>{
      if(value==true){
        list.push({message:`You have unread comments for a task`, href:"/taskcenter"})
        }
    })
    return (list)
  }

  const setMessages=async()=>{
    fetchNotifications()
    .then((messages)=>{
      console.log(messages)
      setNotifications([...messages])
    })
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/signin");
    fetchUserName();
    fetchProfile("uid", user.uid, setProfile);
    setMessages()
  }, [user, loading]);
  
  
  const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false },
    { name: 'Team', href: '/team', icon: UsersIcon, current: false },
    { name: 'Jobs', href: '/jobs', icon: LightningBoltIcon, current: false },
    { name: 'Receipts', href: '/receipts', icon:ReceiptRefundIcon, current:false},
    { name: 'Comms', href: '/taskcenter', icon:CheckCircleIcon, current:false},
    { name: 'Policies', href: '/policies', icon:ArchiveIcon, current:false},
    { name: 'Fleet', href: '/fleet', icon: TruckIcon, current: false },
    { name: 'Sage', href: '/sage', icon: XIcon, current: false },
    { name: 'Monday.com', href: '/monday', icon: OfficeBuildingIcon, current: false },
  ];

  

  
  {/*update the state of obj in navigation array based on url*/}
  let path = useLocation();
  const newNavState =  navigation.map(obj => {
    if (obj.href === path.pathname) {
      return {...obj, current: true };
    }
    return {...obj, current: false};
  });
  

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-neutral-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-14 w-auto"
                      src={sidebarLogo}
                      alt="Voltage Power Solutions"
                    />
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {newNavState.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-neutral-900 text-white'
                              : 'text-gray-300 hover:bg-neutral-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div 
        className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          <div className="flex-1 flex flex-col min-h-0 bg-neutral-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-neutral-900">
              <img
                className="h-14 w-auto"
                src={sidebarLogo}
                alt="Voltage Power Solutions"
              />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav 
              className="flex-1 px-2 py-4 space-y-1">
                {newNavState.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-neutral-900 text-white' : 'text-gray-300 hover:bg-neutral-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4  bg-neutral-800 text-gray-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between bg-neutral-800">
              <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  {/* <label htmlFor="search-field" className="sr-only">
                    Search
                  </label> */}
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600 bg-neutral-800">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      {/* <SearchIcon className="h-5 w-5" aria-hidden="true" /> */}
                    </div>
                    {/* <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent bg-neutral-800 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    /> */}
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* <Menu onChange={({open})=>console.log("open")} as="div" className="ml-3 relative">
                <Menu.Button
                  type="button"
                  className="bg-neutral-800 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onFocus={()=>setViewNotifications(true)}
                  onBlur={()=>setViewNotifications(false)}>
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
                
                {notifications.length !==0 && viewNotifications !== false &&
                  <span className='relative inset-y-3 right-4 bg-red-500 rounded-full text-white text-sm px-1.5 py-0.5'>{notifications.length}</span>
                }
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll">
                    {notifications.map((notification, i)=>{
                      return(
                          <Menu.Item key={i} className='text-left w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                            {notification.message !==undefined &&
                            <span>
                            {`${notification.message} `}
                            <a href={notification.href} className="text-blue-400">Click Here</a>
                            </span>
                        }
                          </Menu.Item>
                      )
                    })}
                    </Menu.Items>
                  </Transition>
                </Menu> */}
                  {/* <button className='bg-green-400 p-2 rounded-md text-white' onClick={()=>console.log(notifications)}>Get the Deets</button> */}
                {/* Profile button */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={profile.profilePicUrl ? profile.profilePicUrl : fallbackAvatar}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {/*Profile Avatar Dropdown Menu*/}

                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                     <Menu.Item className='text-left w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                        <a
                        className=' text-sm text-gray-700 '
                        href='/myprofile'
                        >My Profile</a>
                      </Menu.Item>
                      <Menu.Item className='text-left w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                        <a
                        className=' text-sm text-gray-700 '
                        href='/settings'
                        >Settings</a>
                      </Menu.Item>
                      <Menu.Item className='text-left w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                        <button
                        className=' text-sm text-gray-700 '
                        onClick={logout}
                        >Sign Out</button> 
                      </Menu.Item>
                    </Menu.Items>


                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 overflow-auto">
            
     <Outlet/>
            
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

