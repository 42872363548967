import { useState, useEffect, useRef } from "react"
import { DocumentIcon } from "@heroicons/react/solid"
import { auth, fetchMultiple, updateField, uploadPic, getComments } from "../../Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Comments from '../../components/Comments'
import CommentList from '../../components/CommentList'
import VPSFallbackNoImg from '../../assets/VPSFallbackNoImg.svg'
import { useParams } from "react-router-dom";

export default function SideDisplay({ info, setOpen, complete, setTasks}){

    const attachPhoto=(event)=>{
        try{
        const id=event.target.id
        const image= {Attachment:attachmentUrl}
        updateField("tasks", id, image)
        setAttachmentUrl("")
        console.log("Image Uploaded")
        }
        catch(err){
        alert(err)
        }
    }

    const newPic= ()=>{
        uploadPic("task-attachments", attachment, attachment.name, setAttachmentUrl)
    }

    const backToTop=()=>{
        displayRef.current.scrollTo(
            {top:0,
            left:0,
            behavior:"smooth"
            })
        console.log("up up and away")
    }

    const displayRef= useRef(null);
    const createdBy=info.item.CreatedBy
    const [user]= useAuthState(auth)
    const {member}= useParams()
    const [attachment, setAttachment]= useState(null)
    const [attachmentUrl, setAttachmentUrl]= useState("")
    const [assignerInfo, setAssignerInfo]= useState({})
    const [comments, setComments]=useState([""])
    const searchfile= info.item.AttachmentFileName== "" ? "" : info.item.AttachmentFileName.split('.')
    const filetype=searchfile[1]

    useEffect(()=>{
        fetchMultiple("users", "uid", createdBy, setAssignerInfo)
        getComments("tasks", info.id, setComments)
        backToTop()
    }, [info])

    // function ViewAttachmentImage() {
    //   window.location.href = '{info.item.Attachment}';
    //   return null;
    // }


return(
<>

  <div
        className="flex flex-col fixed top-12 right-0 bg-white overflow-y-scroll h-full w-1/3 border-l border-l-gray-200" ref={displayRef}>
            <div className="mt-2 max-w-8xl mx-auto px-4 sm:px-6">
              <div className="relative text-gray-400 focus-within:text-gray-500">
              </div>
            </div>
            <div className="max-w-8xl mx-auto py-1 px-2 sm:px-4">

            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                </div>
                <div className="ml-3 min-w-0 flex-1">
                    {info.item.Attachment!=="" ?
                    <>
                    {
                      filetype =="pdf"
                      ?
                      <>
                      <div className="h-64 w-64 mb-5 border">
                      <iframe className="h-64 w-64 --tw-scale-x-50 --tw-scale-y-50 origin-top" src={info.item.Attachment}/>
                      </div>
                      <div className="mt-2">
                      <a href={info.item.Attachment} target="_blank" download={info.item.Attachment} className="text-orange-600 px-2 hover:text-orange-700">View</a>
                      </div>
                      </>
                      :
                      filetype !=="jpg" &&
                      filetype !=="png"
                      ?
                      <>
                      <div className="h-64 w-64 mb-5 border">
                        <DocumentIcon className="h-48 w-48 ml-8 flex justify center"/>
                        <p className="flex justify-center">No Preview Avaiable</p>
                      </div>
                      <div>
                        <a href={info.item.Attachment} download className="text-orange-600 px-2 hover:text-orange-700">View</a>
                      </div>
                    </>
                    :
                    <>
                    <div className="w-64 h-64">
                    <img className="h-64 w-64 --tw-scale-x-50 --tw-scale-y-50 origin-top" src={info.item.Attachment}/>
                    </div>
                    <div className="mt-2">
                    <a href={info.item.Attachment} target="_blank" download={info.item.Attachment} className="text-orange-600 px-2 hover:text-orange-700">View</a>
                    </div>
                    </>
                    }
                    </>
                    :
                    <>
                    <img src={VPSFallbackNoImg} className="h-64"/>
                    </>
                    }
                <div className="text-base font-medium text-gray-800 truncate border-b-2 mt-2 py-2">Due: {info.item.DueDate.toDate().toDateString()}</div>
                
                <div className="border-b border-gray-300">
                <div className="text-sm font-medium text-gray-500 max-w-fit p-2">Subject:
                {info.item.Subject ?
                <>
                <p>{info.item.Subject}</p>
                </>
                :
                <>
                <p>N/A</p>
                </>
                }
                </div>
                </div>

                <div className="border-b border-gray-300">
                <div className="text-sm font-medium text-gray-500 max-w-fit p-2">Description: <br/><p>{info.item.Description}</p></div>
                </div>
                {info.item.Completed==true ? 
                <>
                <div className="border-b border-gray-300">
                <div className="text-sm font-medium text-green-400 hover:text-green-600 hover:cursor-pointer max-w-fit p-2"><p id={info.id} onClick={(event)=>complete(event, false)}>Complete</p></div>
                </div>
                {info.item.CompleteDate !== "" &&
                <div className="border-b border-gray-300">
                <div className="text-sm font-medium text-gray-500 max-w-fit p-2">Date of Completion: <br/> {info.item.CompleteDate.toDate().toDateString()}</div>                
                </div>
                }
                </>
                    :
                <>
                <div className="border-b border-gray-300">
                <div className="text-sm font-medium text-red-400 hover:text-red-600 hover:cursor-pointer max-w-fit p-2"><p id={info.id} onClick={(event)=>complete(event, true)}>Not Complete</p></div>              
                </div>
                </>
                }
                <div className="mt-1 sm:mt-0 sm:col-span-6">
                <div className="max-w-lg flex justify-center mt-4 px-4 pt-2 pb-3 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm justify-center text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onInput={(event)=>setAttachment(event.target.files[0])} onChange={()=>newPic()}/>
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, PDF up to 10MB</p>
                  </div>
                </div>
                {attachmentUrl!=="" ? 
                <>
                    <button id={info.id} className='bg-indigo-600 text-white p-2 rounded my-2 w-11/12' onClick={(event)=>attachPhoto(event)}>Upload Photo</button>
                </>
                :
                <div className="p-2"></div>}
                {/* <button className="bg-green-400 text-white p-2 rounded" onClick={()=>console.log(filetype)}>Get the Deets</button> */}
                <Comments task={info.id} setter={setComments}/>
                <CommentList comments={comments}/>
            </div>

                </div>
              </div>
              <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
              </div>
            </div>
        </div>
</>


)}