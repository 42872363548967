import { useState, useEffect } from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, fetchProfile } from '../Firebase'
import { useNavigate, Link } from 'react-router-dom'
import fallbackAvatar from '../assets/VPSFallbackAvatar.svg'


const tabs = [
  { name: 'Profile', href: '#', current: true },
  // { name: 'Files', href: '#', current: false },
  // { name: 'Something Else', href: '#', current: false },
]
          
          function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
          }
          
          export default function MyProfile() {
            const [sidebarOpen, setSidebarOpen] = useState(false)
            const [profile, setProfile]= useState([])
            const [user, loading]= useAuthState(auth)
            const navigate=useNavigate()

            useEffect(() => {
              if (loading) return;
              if (!user) return navigate("/signin");
              fetchProfile("uid", user.uid, setProfile); 
            }, [user, loading, profile]);
            
            return (
    <>
      <div className="h-full flex">
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          

              <article>
                {/* Profile header */}
                <div>
                
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-end sm:space-x-5">
                      <div className="flex">
                        <img
                          className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                          src={profile.profilePicUrl ? profile.profilePicUrl : fallbackAvatar}
                          alt=""
                        />
                      </div>
                      <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                          <h1 className="text-2xl font-bold text-gray-900 truncate">{`${profile.fName} ${profile.lName}`}</h1>
                          <Link className="text-sky-600 hover:text-sky-500" to="/editprofile">Edit Info</Link>
                        </div>
                      </div>
                    </div>
                    <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                      <h1 className="text-2xl font-bold text-gray-900 truncate">{`${profile.fName} ${profile.lName}`}</h1>
                      <Link className="text-sky-600 hover:text-sky-500" to="/editprofile">Edit Info</Link>
                    </div>
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? 'border-orange-600 text-gray-900'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* Description list */}
                <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">{`${profile.fName} ${profile.lName}`}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                        <dd className="mt-1 text-sm text-gray-900">{profile.email}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                        <dd className="mt-1 text-sm text-gray-900">{profile.phoneNumber}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Birthday</dt>
                        <dd className="mt-1 text-sm text-gray-900">{profile.birthday}</dd>
                      </div>
                  </dl>
                </div>
                {/* Team member list */}
                {/* <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
                  <h2 className="text-sm font-medium text-gray-500">Team members</h2>
                  <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {team.map((person) => (
                      <div
                        key={person.handle}
                        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                      >
                        <div className="flex-shrink-0">
                          <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-sm font-medium text-gray-900">{person.name}</p>
                            <p className="text-sm text-gray-500 truncate">{person.role}</p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}